// CustomerProfile.js

import React, { useState, useEffect } from 'react';
import { useCustomer } from '../../context/CustomerContext';
import LoadingScreen from '../../components/LoadingScreen';
import { useNotifications } from '../../context/NotificationContext';
import axios from 'axios';
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaIdBadge,
  FaBuilding,
  FaClock,
  FaEdit,
  FaSave,
  FaTimes,
  FaCamera,
  FaEye,
} from 'react-icons/fa';

const CustomerProfile = () => {
  const { customer, setCustomer } = useCustomer(); // Add setCustomer to update context
  const { addNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [postNumberError, setPostNumberError] = useState('');
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchProfile = async () => {
      if (!customer) return;

      setIsLoading(true);
      setError('');

      try {
        const response = await axios.get(`${apiURL}/api/customers/${customer.id}`);
        setProfile(response.data);
        setFormData({
          ...response.data,
          opt_in_email: response.data.opt_in_email ?? true,
          opt_in_sms: response.data.opt_in_sms ?? true,
          show_graveperson_picture: response.data.show_graveperson_picture ?? true,
        });
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError('Failed to load profile');
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [customer, apiURL]);

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    setFormData({
      ...formData,
      [name]: fieldValue,
    });

    if (name === 'customer_post_number') {
      if (value.length === 4) {
        try {
          const response = await axios.get(`${apiURL}/api/postLocation/${parseInt(value)}`);
          if (response.data.length > 0) {
            const postLocation = response.data[0].Poststed;
            setFormData((prevData) => ({
              ...prevData,
              customer_post_location: postLocation,
            }));
            setPostNumberError('');
          } else {
            setFormData((prevData) => ({
              ...prevData,
              customer_post_location: '',
            }));
            setPostNumberError('Ugyldig postnummer');
          }
        } catch (err) {
          setFormData((prevData) => ({
            ...prevData,
            customer_post_location: '',
          }));
          setPostNumberError('Ugyldig postnummer');
        }
      } else {
        setPostNumberError('');
        setFormData((prevData) => ({
          ...prevData,
          customer_post_location: '',
        }));
      }
    }
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
    setFormData(profile); // Reset form data when toggling edit mode
    setPostNumberError(''); // Reset post number error
  };

  const handleSave = async () => {
    try {
      await axios.put(`${apiURL}/api/customers/${customer.id}/profile`, formData);
      
      // Update the profile state and context with the new data
      setProfile(formData);
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        showGravepersonPicture: formData.show_graveperson_picture, // Update context with new value
      }));
      
      addNotification({ type: 'success', message: 'Profilen er oppdatert!' });
      setIsEditing(false);
    } catch (err) {
      console.error('Error saving profile:', err);
      addNotification({ type: 'error', message: 'Kunne ikke oppdatere profilen.' });
    }
  };

  if (isLoading) return <LoadingScreen />;
  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="bg-white shadow-lg rounded-2xl p-8 max-w-6xl w-full border-t-4 border-green-grave mx-auto">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Din Profil</h1>
      {profile && (
        <div className="space-y-8">
          {/* Profile Card */}
          <div className="bg-gray-50 p-6 rounded-xl shadow-md">
            <div className="flex items-center space-x-6">
              {/* Profile Picture */}
              <div className="relative">
                <img
                  src={profile.profilePicture || '/images/default_picture.jpg'}
                  alt="Profile"
                  className="h-32 w-32 rounded-full object-cover border-4 border-green-grave"
                />
                {isEditing && (
                  <button className="absolute bottom-0 right-0 bg-white p-2 rounded-full shadow-md hover:bg-gray-100">
                    <FaCamera className="text-gray-700" />
                  </button>
                )}
              </div>
              {/* Personal Info */}
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
                    <FaUser className="mr-2 text-green-grave" />
                    Personlig informasjon
                  </h2>
                  {isEditing ? (
                    <div className="flex space-x-2">
                      <button
                        onClick={handleSave}
                        className="bg-green-grave hover:bg-green-600 text-white px-3 py-2 rounded-lg flex items-center"
                      >
                        <FaSave className="mr-1" /> Lagre
                      </button>
                      <button
                        onClick={toggleEditMode}
                        className="bg-red-500 hover:bg-red-600 text-white px-3 py-2 rounded-lg flex items-center"
                      >
                        <FaTimes className="mr-1" /> Avbryt
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={toggleEditMode}
                      className="bg-orange-grave hover:bg-orange-600 text-white px-3 py-2 rounded-lg flex items-center"
                    >
                      <FaEdit className="mr-1" /> Rediger
                    </button>
                  )}
                </div>
                {isEditing ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                    {/* First Name */}
                    <div>
                      <label className="block text-gray-700 mb-1">Fornavn:</label>
                      <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    {/* Last Name */}
                    <div>
                      <label className="block text-gray-700 mb-1">Etternavn:</label>
                      <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    {/* Email */}
                    <div>
                      <label className="block text-gray-700 mb-1">E-post:</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    {/* Phone Number */}
                    <div>
                      <label className="block text-gray-700 mb-1">Telefon:</label>
                      <input
                        type="text"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    {/* Address */}
                    <div className="md:col-span-2">
                      <label className="block text-gray-700 mb-1">Adresse:</label>
                      <input
                        type="text"
                        name="customer_address"
                        value={formData.customer_address}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    {/* Post Number and Location */}
                    <div>
                      <label className="block text-gray-700 mb-1">Postnummer:</label>
                      <input
                        type="text"
                        name="customer_post_number"
                        value={formData.customer_post_number}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-1">Poststed:</label>
                      <input
                        type="text"
                        name="customer_post_location"
                        value={formData.customer_post_location}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-grave"
                      />
                    </div>
                    {postNumberError && (
                      <p className="text-red-500 md:col-span-2">{postNumberError}</p>
                    )}
                    {/* Opt-in Preferences */}
                    <div className="md:col-span-2 mt-4">
                      <h3 className="text-lg font-semibold text-gray-700 mb-2">
                        Kommunikasjonsinnstillinger
                      </h3>
                      <div className="space-y-4">
                        {/* Opt-in Email */}
                        <div className="flex items-start">
                          <input
                            type="checkbox"
                            name="opt_in_email"
                            checked={formData.opt_in_email}
                            onChange={handleChange}
                            id="opt_in_email"
                            className="h-5 w-5 text-green-grave focus:ring-green-grave border-gray-300 rounded mt-1"
                          />
                          <label htmlFor="opt_in_email" className="ml-3">
                            <span className="text-gray-700 font-medium">
                              Jeg ønsker å motta e-post
                            </span>
                            <p className="text-gray-500 text-sm">
                              Send meg e-post når dere foretar besøk.
                            </p>
                          </label>
                        </div>
                        {/* Opt-in SMS */}
                        <div className="flex items-start">
                          <input
                            type="checkbox"
                            name="opt_in_sms"
                            checked={formData.opt_in_sms}
                            onChange={handleChange}
                            id="opt_in_sms"
                            className="h-5 w-5 text-green-grave focus:ring-green-grave border-gray-300 rounded mt-1"
                          />
                          <label htmlFor="opt_in_sms" className="ml-3">
                            <span className="text-gray-700 font-medium">Jeg ønsker å motta SMS</span>
                            <p className="text-gray-500 text-sm">
                              Send meg SMS når dere foretar besøk.
                            </p>
                          </label>
                        </div>
                        <div className="flex items-start">
                        <input
                          type="checkbox"
                          name="show_graveperson_picture"
                          checked={formData.show_graveperson_picture}
                          onChange={handleChange}
                          id="show_graveperson_picture"
                          className="h-5 w-5 text-green-grave focus:ring-green-grave border-gray-300 rounded mt-1"
                        />
                        <label htmlFor="show_graveperson_picture" className="ml-3">
                          <span className="text-gray-700 font-medium">
                            Vis bilde av gravperson
                          </span>
                          <p className="text-gray-500 text-sm">
                            Vis eller skjul bildet av personer som hviler på graven.
                          </p>
                        </label>
                      </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4 space-y-2 text-gray-700">
                    <p className="flex items-center">
                      <FaUser className="mr-2 text-green-grave" />
                      <span className="font-semibold mr-1">Navn:</span>{' '}
                      {profile.first_name} {profile.last_name}
                    </p>
                    <p className="flex items-center">
                      <FaEnvelope className="mr-2 text-green-grave" />
                      <span className="font-semibold mr-1">E-post:</span> {profile.email}
                    </p>
                    <p className="flex items-center">
                      <FaPhone className="mr-2 text-green-grave" />
                      <span className="font-semibold mr-1">Telefon:</span> {profile.phone_number}
                    </p>
                    <p className="flex items-center">
                      <FaMapMarkerAlt className="mr-2 text-green-grave" />
                      <span className="font-semibold mr-1">Adresse:</span>
                    </p>
                    <p className="ml-8">
                      {profile.customer_address}
                      <br />
                      {profile.customer_post_number} {profile.customer_post_location}
                    </p>
                    {/* Opt-in Preferences Display */}
                    <p className="flex items-center">
                      <FaEnvelope className="mr-2 text-green-grave" />
                      <span className="font-semibold mr-1">Ønsker å motta e-post:</span>{' '}
                      {profile.opt_in_email ? 'Ja' : 'Nei'}
                    </p>
                    <p className="flex items-center">
                      <FaPhone className="mr-2 text-green-grave" />
                      <span className="font-semibold mr-1">Ønsker å motta SMS:</span>{' '}
                      {profile.opt_in_sms ? 'Ja' : 'Nei'}
                    </p>
                    <p className="flex items-center">
                      <FaEye className="mr-2 text-green-grave" />
                      <span className="font-semibold mr-1">Vis bilde av gravperson:</span>{' '}
                      {profile.show_graveperson_picture ? 'Ja' : 'Nei'}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Customer Information Card */}
          <div className="bg-gray-50 p-6 rounded-xl shadow-md">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
              <FaIdBadge className="mr-2 text-green-grave" /> Kundeinformasjon
            </h2>
            <div className="space-y-2 text-gray-700">
              <p className="flex items-center">
                <FaIdBadge className="mr-2 text-green-grave" />
                <span className="font-semibold mr-1">Kundenummer:</span>{' '}
                {profile.customer_number}
              </p>
              <p className="flex items-center">
                <FaBuilding className="mr-2 text-green-grave" />
                <span className="font-semibold mr-1">Org-nummer:</span>{' '}
                {profile.org_number || 'Ikke oppgitt'}
              </p>
              <p className="flex items-center">
                <FaBuilding className="mr-2 text-green-grave" />
                <span className="font-semibold mr-1">Firma:</span>{' '}
                {profile.is_company ? profile.company_name : 'Ikke et firma'}
              </p>
              <p className="flex items-center">
                <FaClock className="mr-2 text-green-grave" />
                <span className="font-semibold mr-1">Sist pålogget:</span>{' '}
                {profile.last_login
                  ? new Date(profile.last_login).toLocaleString('nb-NO')
                  : 'Aldri'}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerProfile;