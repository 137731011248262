import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { FaMap } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import Pagination from '../Pagination'; // Assuming Pagination component is in the same directory
import ImageWithFallback from "../ImageWithFallback";

const GravestoneListCustomer = ({
  fetchId,
  queryParam,
  showAddNewGravestone = true,
  showSearch = false,
  showMapButton = false,
  showLimit = true
}) => {
  const [gravestones, setGravestones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const newGravestonePath = `/gravsteiner/ny/${fetchId}`;
  const apiURL = process.env.REACT_APP_API_BASE_URL || "";
  const mainURL = process.env.REACT_APP_MAIN_URL || "";

  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${apiURL}/api/gravestones?${queryParam}=${fetchId}&limit=${limit}&search=${search}&page=${page}`
        );
        const data = await response.json();
        setIsLoading(false);
        if (data.gravestones) {
          setGravestones(data.gravestones);
          setTotalPages(data.totalPages);
        } else {
          setError("No gravestones found");
        }
      } catch (err) {
        setIsLoading(false);
        setError("Failed to fetch gravestones");
      }
    };

    fetchData();
  }, [fetchId, queryParam, search, limit, page]);

  if (error) return <div className="text-red-500 text-center py-4">{error}</div>;

  return (
    <div>
      {/* Controls */}
      <div className="flex items-center flex-wrap mb-4 space-x-4">
        {showSearch && (
          <div className="relative">
            <FiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              value={search}
              onChange={handleSearchChange}
              placeholder="Søk..."
              className="pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-green-grave"
            />
          </div>
        )}
        {showLimit && (
          <select
            value={limit}
            onChange={handleLimitChange}
            className="px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-green-grave"
          >
            <option value="5">5 per side</option>
            <option value="10">10 per side</option>
            <option value="20">20 per side</option>
            <option value="50">50 per side</option>
          </select>
        )}
        {showAddNewGravestone && (
          <Link
            to={newGravestonePath}
            className="btn btn-primary flex items-center"
          >
            <AiOutlinePlus className="mr-2" /> Legg til ny gravstein
          </Link>
        )}
      </div>

      {/* Gravestones Grid */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {gravestones.map((gravestone) => (
          <div key={gravestone.id} className="bg-white rounded-lg shadow">
            <Link to={`/gravsteiner/${gravestone.id}`}>
              <ImageWithFallback
  src={gravestone.thumbnail || gravestone.picture}
  mainURL={mainURL}
  fallbackSrc="https://via.placeholder.com/150"
  alt="Gravestone Thumbnail"
  className="w-full h-48 object-cover rounded-t-lg"
/>
            </Link>
            <div className="p-4">
              <h4 className="font-medium text-lg">
                {gravestone.gravepersons_names || 'Ukjent'}
              </h4>
              <p className="text-sm text-gray-600">
                Siste besøk: {gravestone.latest_visit_date ? new Date(gravestone.latest_visit_date).toLocaleDateString('nb-NO') : 'Ukjent'}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
      )}
    </div>
  );
};

export default GravestoneListCustomer;