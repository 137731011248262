import React from 'react';
import { MdLocationOff } from 'react-icons/md';
import { FaBirthdayCake, FaCross } from 'react-icons/fa'; // Importing icons
import { format } from 'date-fns';

const WorkorderGravestoneCard = ({
  gravestone,
  apiURL,
  openModal,
  getStatusColor,
  initiateVisit,
}) => {
  // Get graveperson details with birthdate and deathdate
  const gravepersonDetails =
    gravestone.gravepersons && gravestone.gravepersons.length > 0
      ? gravestone.gravepersons.map((person) => {
          const fullName = `${person.first_name} ${person.last_name}`;
          const birthdate = person.birthdate
            ? format(new Date(person.birthdate), 'dd.MM.yyyy')
            : 'Ukjent';
          const deathdate = person.deathdate
            ? format(new Date(person.deathdate), 'dd.MM.yyyy')
            : 'Ukjent';
          return (
            <div key={person.id} className="text-xs mb-1">
              <span className="font-semibold">{fullName}</span>
              {/* Birthdate and deathdate under the name with icons */}
              <div className="flex items-center text-gray-600">
                <FaBirthdayCake className="mr-1" />
                <span>{birthdate}</span>
                <FaCross className="mx-2" />
                <span>{deathdate}</span>
              </div>
            </div>
          );
        })
      : 'Ukjent';

  // Display products
  const productTags =
    gravestone.products && gravestone.products.length > 0
      ? gravestone.products.map((product) => (
          <span
            key={product.id}
            className="inline-block bg-gray-200 text-gray-800 text-xs px-2 py-1 mr-1 mt-1 rounded-full"
          >
            {product.name}
          </span>
        ))
      : null;

  // Check if the gravestone visit is not completed
  const isVisitable = Number(gravestone.visitStatusId) !== 3;

  return (
    <div className="bg-white overflow-hidden shadow-lg flex">
      {/* Left Side: Image */}
      <div
        className="relative cursor-pointer w-1/4 h-full"
        onClick={() => openModal(apiURL + gravestone.picture)}
      >
        <img
          src={
            gravestone.thumbnail && gravestone.thumbnail !== 'NULL'
              ? apiURL + gravestone.thumbnail
              : '/images/default_grave.jpg'
          }
          alt="Gravestone"
          className="w-full h-full object-cover"
        />
        {(!gravestone.latitude ||
          !gravestone.longitude ||
          gravestone.latitude === 'NULL' ||
          gravestone.longitude === 'NULL') && (
          <div className="absolute top-0 left-0 m-1 flex items-center text-white bg-black bg-opacity-50 rounded px-1 py-0.5">
            <MdLocationOff className="text-xs mr-1" />
            <p className="text-xs">Ikke på kart</p>
          </div>
        )}
      </div>

      {/* Right Side: Info */}
      <div
        className={`w-3/4 px-3 py-2 flex flex-col justify-between ${
          isVisitable ? 'cursor-pointer hover:bg-gray-50' : ''
        }`}
        onClick={() => isVisitable && initiateVisit(gravestone.id)}
      >
        {/* Top Section: Graveperson Details and Status */}
        <div className="flex justify-between items-start">
          {/* Display graveperson names with dates */}
          <div className="flex flex-col">{gravepersonDetails}</div>

          {/* Display status */}
          <span
            className={`ml-2 px-2 py-0.5 rounded-full text-xs font-medium whitespace-nowrap ${getStatusColor(
              gravestone.visitStatusId
            )}`}
          >
            {gravestone.visitStatus}
          </span>
        </div>

        {/* Middle Section: Products */}
        {productTags && <div className="mt-1 flex flex-wrap">{productTags}</div>}

        {/* Bottom Section: Location and Distance */}
        <div className="flex justify-between items-center mt-2">
          {/* Display location */}
          <p className="text-xs truncate">
            <strong>Lokasjon:</strong> {gravestone.location || 'N/A'}
          </p>

          {/* Display distance */}
          <p className="text-xs ml-2 whitespace-nowrap">
            {gravestone.distance === Number.MAX_VALUE
              ? `Ukjent avstand`
              : `Avstand: ${(gravestone.distance / 1000).toFixed(1)} km`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WorkorderGravestoneCard;