function requestGeolocation() {
    // Check if Geolocation API is available
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Handle successful geolocation here (e.g., save coordinates, use them in your app)
        },
        (error) => {
          
          // Handle error here (e.g., user denied geolocation)
        },
        {
          // Optional: Geolocation options
          maximumAge: 60000, // Accept cached position within 60 seconds
          timeout: 5000, // Stop trying after 5 seconds
          enableHighAccuracy: true // Request more precise position
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      // Handle the case where geolocation is not supported
    }
  }

  export default requestGeolocation;