import React from 'react';
import { Link } from 'react-router-dom';

const DetailCard = ({ icon, label, value, linkTo }) => {
  return (
    <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex flex-col sm:flex-row items-start sm:items-center hover:bg-gray-100 transition-colors">
      <span className="font-bold text-gray-700 flex items-center mb-2 sm:mb-0">
        {icon}
        <span className="ml-2">{label}</span>
      </span>
      <span className="sm:ml-2 text-gray-600">
        {linkTo ? (
          <Link to={linkTo} className="text-green-grave hover:underline">
            {value}
          </Link>
        ) : (
          value
        )}
      </span>
    </div>
  );
};

export default DetailCard;