import React, { useEffect } from "react";
import { FaExclamationTriangle, FaTimes } from "react-icons/fa";
import PropTypes from "prop-types";

const WarningModal = ({ isOpen, text, onClose }) => {
  // Close the modal when the Escape key is pressed
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen, onClose]);

  // Prevent scrolling when the modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  // Close the modal when clicking outside the modal content
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    // Overlay with fade-in animation
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
      onClick={handleOverlayClick}
      aria-labelledby="warning-modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* Modal Content with scale animation */}
      <div
        className={`bg-white rounded-lg shadow-lg w-11/12 max-w-md p-6 relative transform transition-transform duration-300 ${
          isOpen ? "scale-100" : "scale-95"
        }`}
      >
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={onClose}
          aria-label="Close modal"
        >
          <FaTimes size={20} />
        </button>

        {/* Warning Icon */}
        <div className="flex justify-center mb-4">
          <FaExclamationTriangle size={50} className="text-yellow-500" />
        </div>

        {/* Modal Text */}
        <div className="text-center">
          <p className="text-lg text-gray-700">{text}</p>
        </div>

        {/* Action Buttons */}
        <div className="mt-6 flex justify-center space-x-4">
          <button
            onClick={onClose}
            className="bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 transition"
          >
            Forstått
          </button>
        </div>
      </div>
    </div>
  );
};

WarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WarningModal;