import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import imageCompression from 'browser-image-compression';
import axiosInstance from "../../utils/axiosInstance";
import { useNotifications } from "../../context/NotificationContext";
import { AiOutlineCamera } from "react-icons/ai";
import { FaSpinner, FaThumbsDown } from "react-icons/fa";
import { useLogs } from "../../hooks/useLogs";
import { useUser } from "../../context/UserContext";
import WarningModal from "../../components/WarningModal";

const VisitEditPage = () => {
  const { visitId } = useParams();
  const [visitDetails, setVisitDetails] = useState({
    visitDetails: {
      before_pictures: null,
      after_pictures: null,
    },
  });
  const [warning, setWarning] = useState(null);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [location, setLocation] = useState(null);
  const [geolocationWatcherId, setGeolocationWatcherId] = useState(null);
  const [geolocationAccuracy, setGeolocationAccuracy] = useState("high");
  const [beforeImagePreview, setBeforeImagePreview] = useState(null);
  const [afterImagePreview, setAfterImagePreview] = useState(null);
  const [beforeImageId, setBeforeImageId] = useState(null);
  const [afterImageId, setAfterImageId] = useState(null);
  const [thumbDown, setThumbDown] = useState(false);

  const [uploadStep, setUploadStep] = useState({
    before: '',
    after: '',
  });
  const [uploadProgress, setUploadProgress] = useState({
    before: 0,
    after: 0,
  });
  const [uploadingImageType, setUploadingImageType] = useState(null);

  const { addNotification } = useNotifications();
  const { createLog } = useLogs();
  const { user } = useUser();

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const navigate = useNavigate();

  const openWarningModal = () => {
    setIsWarningModalOpen(true);
  };

  const closeWarningModal = () => {
    setIsWarningModalOpen(false);
  };

  useEffect(() => {
    if (warning) {
      openWarningModal();
    }
  }, [warning]);

  useEffect(() => {
    axiosInstance.get(`/api/visits/${visitId}`)
      .then((response) => {
        const data = response.data;
        setVisitDetails(data);
        setWarning(data.visitDetails.warning);
        setComment(data.visitDetails.description);
        setThumbDown(data.visitDetails.thumb_down);
        const combinedActivities = Array.from(new Set([
          ...data.selectedActivities.map((a) => a.id),
          ...data.plannedActivities.map((a) => a.id),
        ]));
        
        setSelectedActivities(combinedActivities);
        setLoading(false);
        console.log(data.visitDetails);
      })
      .catch((error) => {
        console.error("Error fetching visit details:", error);
        setError("Failed to load visit details");
        setLoading(false);
      });
  }, [visitId]);

  useEffect(() => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by your browser');
      return;
    }
  
    const watcherId = navigator.geolocation.watchPosition(
      (position) => {
        setLocation(position.coords);
        setGeolocationAccuracy(position.coords.accuracy <= 20 ? 'high' : 'low');
      },
      (error) => {
        
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 15000, // Increased timeout
      }
    );
  
    setGeolocationWatcherId(watcherId);
  
    return () => {
      if (navigator.geolocation && geolocationWatcherId !== null) {
        navigator.geolocation.clearWatch(geolocationWatcherId);
      }
    };
  }, []);

  const toggleActivitySelection = (activityId) => {
    setSelectedActivities((current) =>
      current.includes(activityId)
        ? current.filter((id) => id !== activityId)
        : [...current, activityId]
    );
  };

  const handleCapture = (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    if (type === "before") {
      setBeforeImagePreview(URL.createObjectURL(file));
    } else if (type === "after") {
      setAfterImagePreview(URL.createObjectURL(file));
    }

    (async () => {
      setUploadingImageType(type);

      try {
        if (!location) {
          throw new Error('Posisjon er ikke tilgjengelig ennå. Vennligst vent et øyeblikk og prøv igjen.');
        }

        setUploadStep((prev) => ({ ...prev, [type]: 'compressing' }));

        const compressedFile = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        });

        setUploadStep((prev) => ({ ...prev, [type]: 'uploading' }));

        const formData = new FormData();
        formData.append("image", compressedFile);
        formData.append("latitude", location.latitude);
        formData.append("longitude", location.longitude);
        formData.append("gravestoneId", visitDetails.visitDetails.gravestone_id);
        formData.append("type", type);
        formData.append("visitId", visitId);

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [type]: percentComplete,
            }));
          },
        };

        axiosInstance.post('/api/pictures/upload', formData, config)
          .then((response) => {
            const result = response.data;
            if (type === "before") {
              setBeforeImageId(result.pictureId);
              createLog({
                type: "action",
                message: `Før-bilde lastet opp for besøk ${visitId}`,
                userId: user.id,
              });
            } else if (type === "after") {
              setAfterImageId(result.pictureId);
              createLog({
                type: "action",
                message: `Etter-bilde lastet opp for besøk ${visitId}`,
                userId: user.id,
              });
              handleSave(result.pictureId);
            }

            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [type]: 0,
            }));
            setUploadingImageType(null);
            setUploadStep((prev) => ({ ...prev, [type]: '' }));
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
            addNotification({
              message: "Feil ved opplasting av bilde.",
              type: "error",
            });
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [type]: 0,
            }));
            setUploadingImageType(null);
            setUploadStep((prev) => ({ ...prev, [type]: '' }));
          });

      } catch (error) {
        console.error("Error during image upload:", error);
        addNotification({
          message: error.message || "Feil ved bildeopplasting.",
          type: "error",
        });
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [type]: 0,
        }));
        setUploadingImageType(null);
        setUploadStep((prev) => ({ ...prev, [type]: '' }));
      }
    })();
  };

  const handleGoingBack = () => {
    navigate(`/arbeidslister/${visitDetails.visitDetails.workorder_id}`);
  };

  const handleSave = async (updatedAfterImageId = null) => {
    const afterImageIdToUse = updatedAfterImageId || afterImageId;
  
    const requestBody = {
      visitId,
      selectedActivities,
      comment,
      beforeImageId,
      afterImageId: afterImageIdToUse,
      thumb_down: thumbDown,
    };
  
    console.log("Saving visit details:", requestBody);
  
    try {
      await axiosInstance.post('/api/visit/update', requestBody);
  
      addNotification({ message: "Besøk oppdatert", type: "success" });
  
      if (afterImageIdToUse) {
        createLog({
          type: "action",
          message: `Besøk ${visitId} er ferdigstilt`,
          userId: user.id,
        });
        navigate(`/arbeidslister/${visitDetails.visitDetails.workorder_id}`);
      }
  
    } catch (error) {
      console.error("Error saving visit details:", error);
      addNotification({
        message: "Kunne ikke oppdatere besøket.",
        type: "error",
      });
      createLog({
        type: "error",
        message: `Kunne ikke oppdatere besøk ${visitId}. Error: ${error}`,
        userId: user.id,
      });
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!visitDetails) return <div>No visit details found</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Besøk</h1>

      <div className="mb-4">
  <h2 className="text-lg font-semibold">Aktiviteter:</h2>
  <div className="flex flex-wrap gap-1">
    {visitDetails.allActivities.map((activity) => (
      <button
        key={activity.id}
        className={`px-2 py-1 rounded-full text-sm ${
          selectedActivities.includes(activity.id)
            ? "bg-green-grave text-white"
            : "bg-gray-200 text-gray-800"
        } ${
          visitDetails.plannedActivities.find((a) => a.id === activity.id)
            ? "ring-2 ring-orange-grave"
            : ""
        }`}
        onClick={() => toggleActivitySelection(activity.id)}
      >
        {activity.name}
      </button>
    ))}
  </div>
</div>

      <div className="mb-4">
        <label
          htmlFor="comment"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Kommentar:
        </label>
        <textarea
          id="comment"
          rows="4"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
          placeholder="Legg til kommentar.."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-4">
        {["before", "after"].map((type) => (
          <div
            key={type}
            className="border p-4 h-32 flex justify-center items-center relative"
          >
            <label
              htmlFor={`capture${type}`}
              className="cursor-pointer w-full h-full flex justify-center items-center relative"
            >
              {(type === "before" &&
                (beforeImagePreview ||
                  visitDetails.visitDetails.before_picture_url)) ||
              (type === "after" &&
                (afterImagePreview ||
                  visitDetails.visitDetails.after_picture_url)) ? (
                <img
                  src={
                    type === "before"
                      ? beforeImagePreview ||
                        visitDetails.visitDetails.before_picture_url
                      : afterImagePreview ||
                        visitDetails.visitDetails.after_picture_url
                  }
                  alt={type}
                  className="max-w-full max-h-40 object-cover w-full h-full"
                />
              ) : (
                <AiOutlineCamera size={48} />
              )}

              {/* Show spinner or geolocation status only for the image being uploaded */}
              {uploadingImageType === type && (
                <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex flex-col justify-center items-center">
                  {uploadStep[type] === 'compressing' && (
                    <>
                      <FaSpinner className="text-white animate-spin" size={24} />
                      <span className="text-white">Komprimerer bilde...</span>
                    </>
                  )}
                  {uploadStep[type] === 'uploading' && (
                    <>
                      <div className="w-3/4 bg-gray-200 rounded-full h-2 mb-2">
                        <div
                          className="bg-green-grave h-2 rounded-full"
                          style={{ width: `${uploadProgress[type]}%` }}
                        ></div>
                      </div>
                      <span className="text-white">
                        Laster opp... {Math.round(uploadProgress[type])}%
                      </span>
                    </>
                  )}
                </div>
              )}

              {/* Show 'Waiting for location' if location is not yet available */}
              {!location && (
                <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex flex-col justify-center items-center">
                  <FaSpinner className="text-white animate-spin" size={24} />
                  <span className="text-white">Venter på posisjon...</span>
                </div>
              )}

              {geolocationAccuracy === "low" && (
                <div className="absolute top-0 left-0 bg-yellow-400 text-black px-2 py-1 text-xs">
                  Lav nøyaktighet
                </div>
              )}

              <input
                type="file"
                id={`capture${type}`}
                accept="image/*"
                capture="environment"
                onChange={(e) => handleCapture(e, type)}
                className="hidden"
              />
            </label>
          </div>
        ))}
      </div>
      {/* Thumbs Down Toggle */}
<div className="mb-4 flex justify-center">
  <button
    onClick={() => setThumbDown(!thumbDown)}
    className={`flex items-center justify-center p-4 border rounded-lg ${
      thumbDown ? "bg-red-500 text-white" : "bg-gray-200 text-gray-800"
    }`}
  >
    <FaThumbsDown size={48} />
  </button>
</div>

      <button
        className="px-4 py-2 bg-green-grave text-white rounded hover:bg-green-700"
        onClick={() => handleSave()}
      >
        Lagre
      </button>
      <button
        className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 ml-2"
        onClick={handleGoingBack}
      >
        Tilbake
      </button>

      {/* Warning Modal */}
      <WarningModal
        isOpen={isWarningModalOpen}
        text={warning}
        onClose={closeWarningModal}
      />
    </div>
  );
};

export default VisitEditPage;