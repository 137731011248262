import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom'; 
import { useUser } from '../../context/UserContext';

const WorkordersUser = () => {
  const [workOrders, setWorkOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ignoreFinished, setIgnoreFinished] = useState(true);
  const [error, setError] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';
  const { user } = useUser();

  const fetchWorkOrders = useCallback(async () => {
    if (!user || !user.id) {
      console.log("User ID is not available.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(
        `${apiURL}/api/user/workorders?userId=${user.id}&ignoreFinished=${ignoreFinished}`
      );
      const workOrdersData = await response.json();
      setWorkOrders(workOrdersData.workorders);
    } catch (error) {
      console.error("Failed to fetch work orders:", error);
      setError('Failed to load work orders. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [apiURL, user, ignoreFinished]);

  useEffect(() => {
    fetchWorkOrders();
  }, [fetchWorkOrders]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Failed to get user location:", error);
        }
      );
    }
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('nb-NO', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <div className="p-4">
      <h1 className="text-3xl font-semibold text-gray-800 mb-8">Dine Arbeidsordre</h1>
      {isLoading ? (
        <div className="flex justify-center">
          <p>Laster...</p>
        </div>
      ) : error ? (
        <div className="text-center text-red-500">{error}</div>
      ) : workOrders.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {workOrders.map((order) => (
            <Link to={`/arbeidslister/${order.id}`} key={order.id} className="bg-white rounded-xl overflow-hidden shadow transition duration-300 hover:shadow-xl">
              <div className="p-6 hover:bg-blue-50">
                <h2 className="text-xl font-bold text-blue-600 mb-4">{order.name}</h2>
                <div className="flex items-center mb-2 text-gray-600">
                  <svg className="w-5 h-5 mr-2 fill-current text-blue-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M6 2a1 1 0 011 1v1h6V3a1 1 0 112 0v1h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V6c0-1.1.9-2 2-2h1V3a1 1 0 011-1zm2 4v10h8V6H8zm0 0H5v10h2V6zm10 0h-2v10h2V6zm-3 2v2h-2V8h2zm-4 0v2H9V8h2zm-4 0v2H5V8h2z"/></svg>
                  <p className="text-sm">Start: {formatDate(order.work_date)}</p>
                </div>
                <div className="flex items-center mb-2 text-gray-600">
                  <svg className="w-5 h-5 mr-2 fill-current text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-4V3a1 1 0 00-1-1H9zm0 2h2v1h4v1H4V5h4V4zm-5 3h12v9H4V9z"/></svg>
                  <p className="text-sm">Frist: {formatDate(order.due_date)}</p>
                </div>
                {order.graveyard_names && (
                  <div className="flex items-center mb-2 text-gray-600">
                    <svg className="w-5 h-5 mr-2 fill-current text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fillRule="evenodd" d="M4 2a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H4zm2 4a1 1 0 011 1v6a1 1 0 11-2 0V7a1 1 0 011-1zm5 0a1 1 0 011 1v6a1 1 0 11-2 0V7a1 1 0 011-1zm5 0a1 1 0 011 1v6a1 1 0 11-2 0V7a1 1 0 011-1z" clipRule="evenodd"/></svg>
                    <p className="text-sm">Kirkegårder: {order.graveyard_names}</p>
                  </div>
                )}
                <div className="flex items-center mb-2 text-gray-600">
                  <svg className="w-5 h-5 mr-2 fill-current text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fillRule="evenodd" d="M4 2a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H4zm2 4a1 1 0 011 1v6a1 1 0 11-2 0V7a1 1 0 011-1zm5 0a1 1 0 011 1v6a1 1 0 11-2 0V7a1 1 0 011-1zm5 0a1 1 0 011 1v6a1 1 0 11-2 0V7a1 1 0 011-1z" clipRule="evenodd"/></svg>
                  <p className="text-sm">Gravsteiner: {order.gravestone_count} ({order.completed_visits_count} ferdige)</p>
                </div>
                <div className="flex items-center mb-4 text-gray-600">
                  <svg className="w-5 h-5 mr-2 fill-current text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 2a8 8 0 100 16 8 8 0 000-16zm0 2a6 6 0 110 12 6 6 0 010-12zm-3 6a3 3 0 006 0h2a5 5 0 11-10 0h2z"/></svg>
                  <p className="text-sm">Status: {order.status_name}</p>
                </div>
                <p className="text-sm text-gray-600">Aktiviteter: {order.activity_names}</p>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500">Ingen arbeidsordre funnet.</p>
      )}
    </div>
  );
};

export default WorkordersUser;