import React, { useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { FaBirthdayCake, FaCross, FaEdit } from "react-icons/fa";

// Custom Modal component
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      onClick={onClose} // Close modal when clicking outside
    >
      <div
        className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Rediger gravperson</h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const PersonCard = ({ person, onSave, showPicture = true, linkToGraveperson = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedPerson, setEditedPerson] = useState({ ...person });

  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append("first_name", editedPerson.first_name);
      formData.append("last_name", editedPerson.last_name);
      formData.append("description", editedPerson.description || "");
      formData.append("birthdate", editedPerson.birthdate || "");
      formData.append("deathdate", editedPerson.deathdate || "");
      formData.append("minneside_url", editedPerson.minneside_url || "");
      formData.append("dodsannonse_url", editedPerson.dodsannonse_url || "");

      if (editedPerson.picture instanceof File) {
        formData.append("picture", editedPerson.picture);
      }

      await axiosInstance.put(
        `/api/gravepersons/${editedPerson.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsModalOpen(false);
      onSave();
    } catch (error) {
      console.error("Error updating graveperson:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "picture") {
      setEditedPerson({
        ...editedPerson,
        [name]: files[0],
        picturePreview: URL.createObjectURL(files[0]),
      });
    } else {
      setEditedPerson({ ...editedPerson, [name]: value });
    }
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  return (
    <>
      <div className="bg-gray-50 rounded-lg shadow-md hover:bg-gray-100 transition-colors flex">
        {Boolean(showPicture) && person.picture && (
          <img
            src={person.picture}
            alt="Person Picture"
            className="w-32 h-32 object-cover rounded-l-lg"
          />
        )}
        <div className="p-4 flex-1 flex items-center justify-between">
          <div>
            <h3 className="text-xl font-semibold text-gray-800 flex items-center">
              {linkToGraveperson ? (
                <Link
                  to={`/gravpersoner/${person.id}`}
                  className="hover:underline text-green-grave hover:text-green-800 flex items-center"
                >
                  {person.first_name} {person.last_name}
                </Link>
              ) : (
                <>
                  {person.first_name} {person.last_name}
                </>
              )}
              <FaEdit
                className="ml-2 text-gray-500 cursor-pointer hover:text-gray-700"
                onClick={handleEditClick}
                title="Rediger person"
              />
            </h3>
            <div className="mt-2 flex flex-col space-y-2">
              <div className="flex items-center space-x-2">
                <FaBirthdayCake className="text-gray-800" />
                <p className="text-gray-700">
                  {person.birthdate
                    ? new Date(person.birthdate).toLocaleDateString("nb-NO")
                    : "Ukjent fødselsdato"}
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <FaCross className="text-gray-800" />
                <p className="text-gray-700">
                  {person.deathdate
                    ? new Date(person.deathdate).toLocaleDateString("nb-NO")
                    : "Ukjent dødsdato"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <div className="flex space-x-4">
              {person.minneside_url && (
                <a
                  href={person.minneside_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center"
                >
                  <img
                    src="/images/minneside.png"
                    alt="Minneside"
                    className="w-10 h-10 cursor-pointer hover:scale-110 transition-transform duration-200"
                  />
                  <p className="text-center text-xs mt-1">Minneside</p>
                </a>
              )}
              {person.dodsannonse_url && (
                <a
                  href={person.dodsannonse_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center"
                >
                  <img
                    src="/images/dodsannonse.png"
                    alt="Dødsannonse"
                    className="w-10 h-10 cursor-pointer hover:scale-110 transition-transform duration-200"
                  />
                  <p className="text-center text-xs mt-1">Dødsannonse</p>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="space-y-4">
          <label className="block">
            <span className="text-gray-700 font-semibold">Fornavn</span>
            <input
              type="text"
              name="first_name"
              value={editedPerson.first_name}
              onChange={handleChange}
              className="block w-full mt-1 px-3 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-100 text-gray-700 shadow-sm"
            />
          </label>
          <label className="block">
            <span className="text-gray-700 font-semibold">Etternavn</span>
            <input
              type="text"
              name="last_name"
              value={editedPerson.last_name}
              onChange={handleChange}
              className="block w-full mt-1 px-3 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-100 text-gray-700 shadow-sm"
            />
          </label>
          <label className="block">
            <span className="text-gray-700 font-semibold">Fødselsdato</span>
            <input
              type="date"
              name="birthdate"
              value={formatDateForInput(editedPerson.birthdate)}
              onChange={handleChange}
              className="block w-full mt-1 px-3 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-100 text-gray-700 shadow-sm"
            />
          </label>
          <label className="block">
            <span className="text-gray-700 font-semibold">Dødsdato</span>
            <input
              type="date"
              name="deathdate"
              value={formatDateForInput(editedPerson.deathdate)}
              onChange={handleChange}
              className="block w-full mt-1 px-3 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-100 text-gray-700 shadow-sm"
            />
          </label>
          <label className="block">
            <span className="text-gray-700 font-semibold">Minneside URL</span>
            <input
              type="url"
              name="minneside_url"
              value={editedPerson.minneside_url}
              onChange={handleChange}
              className="block w-full mt-1 px-3 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-100 text-gray-700 shadow-sm"
            />
          </label>
          <label className="block">
            <span className="text-gray-700 font-semibold">Dødsannonse URL</span>
            <input
              type="url"
              name="dodsannonse_url"
              value={editedPerson.dodsannonse_url}
              onChange={handleChange}
              className="block w-full mt-1 px-3 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-100 text-gray-700 shadow-sm"
            />
          </label>
          <label className="block">
            <span className="text-gray-700 font-semibold">Bilde</span>
            <input
              type="file"
              accept="image/*"
              name="picture"
              onChange={handleChange}
              className="block w-full mt-1 px-3 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none bg-gray-100 text-gray-700 shadow-sm"
            />
            {editedPerson.picturePreview && (
              <img
                src={editedPerson.picturePreview}
                alt="Forhåndsvisning"
                className="mt-2 w-32 h-32 object-cover rounded-lg shadow-md"
              />
            )}
            {!editedPerson.picturePreview && editedPerson.picture && (
              <img
                src={editedPerson.picture}
                alt="Gravperson"
                className="mt-2 w-32 h-32 object-cover rounded-lg shadow-md"
              />
            )}
          </label>
        </div>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={() => setIsModalOpen(false)}
            className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-black rounded-lg shadow-md"
          >
            Avbryt
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg shadow-md"
          >
            Lagre
          </button>
        </div>
      </Modal>
    </>
  );
};

export default PersonCard;
