import React, { useEffect } from 'react';

const ImageModal = ({ isOpen, onClose, imageSrc }) => {
  useEffect(() => {
    if (!isOpen) return;

    const handleEscKey = (e) => {
      if (e.key === 'Escape') onClose();
    };

    window.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 bg-black bg-opacity-75 flex items-center justify-center"
      onClick={onClose} // Close modal on any click
    >
      <div className="relative max-w-screen max-h-screen w-full h-full">
        <img
          src={imageSrc}
          alt="Full size"
          className="object-contain w-full h-full"
        />
      </div>
    </div>
  );
};

export default ImageModal;