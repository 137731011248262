import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaStar } from 'react-icons/fa';

const Rating = ({ visit_type, visit_id, gravestone_id, customer_id, readOnly = false }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(null);
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    if (customer_id && gravestone_id && visit_type && visit_id) {
      fetchRating();
    }
  }, [customer_id, gravestone_id, visit_type, visit_id]);

  const fetchRating = async () => {
    try {
      const response = await axios.get(`${apiURL}/api/ratings`, {
        params: { customer_id, gravestone_id, visit_type, visit_id },
      });
      if (response.data && response.data.rating) {
        setRating(response.data.rating);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setRating(0); // No rating found, initialize with 0
      } else {
        console.error('Error fetching rating:', error); // Log other errors
      }
    }
  };

  const handleClick = async (newRating) => {
    if (readOnly) return;

    try {
      await axios.post(`${apiURL}/api/ratings`, {
        customer_id,
        gravestone_id,
        visit_type,
        visit_id,
        rating: newRating,
      });
      setRating(newRating);
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  return (
    <div className="flex">
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;

        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => handleClick(ratingValue)}
              style={{ display: 'none' }}
              readOnly={readOnly}
            />
            <FaStar
              className={`star ${readOnly ? '' : 'cursor-pointer'}`}
              color={ratingValue <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
              size={24}
              onMouseEnter={() => !readOnly && setHover(ratingValue)}
              onMouseLeave={() => !readOnly && setHover(null)}
            />
          </label>
        );
      })}
    </div>
  );
};

export default Rating;