import axios from 'axios';

const apiURL = process.env.REACT_APP_API_BASE_URL || '';

const axiosInstance = axios.create({
  baseURL: apiURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      try {
        await refreshToken(); // Refresh token if session expired
        return axiosInstance(error.config); // Retry the request
      } catch (refreshError) {
        localStorage.clear(); // Clear tokens if refresh fails
        window.location.href = '/logginn'; // Redirect to login
      }
    }
    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  const storedRefreshToken = localStorage.getItem('refreshToken');
  const response = await axios.post(`/api/token`, { token: storedRefreshToken });
  const data = response.data;

  localStorage.setItem('accessToken', data.accessToken);
  const newExpiration = Date.now() + 2 * 60 * 60 * 1000;
  localStorage.setItem('accessTokenExpiration', newExpiration);
};

export default axiosInstance;