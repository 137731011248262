import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithFallback from '../../components/ImageWithFallback'; // Adjust the path if necessary
import noImage from '../../images/no_image.png';

const CardView = ({ visits }) => (
  <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
    {visits.map((visit) => (
      <Link
        to={`/kunde/besok/${visit.id}/${visit.is_legacy ? '1' : '0'}`}
        key={visit.id}
        className='bg-white rounded-lg shadow-md overflow-hidden'
      >
        <div className='relative'>
          <ImageWithFallback
            src={visit.after_picture_url}
            fallbackSrc={noImage}
            alt="Visit"
            className='w-full h-96 object-cover'
          />
          <div className='absolute top-2 left-2 bg-white bg-opacity-75 p-2 rounded-md'>
            <p className='text-gray-800 font-semibold'>Besøksdato: {new Date(visit.visit_date).toLocaleDateString('nb-NO')}</p>
          </div>
        </div>
        <div className='p-4'>
          <h2 className='text-lg font-semibold text-gray-800 mb-2'>{visit.gravepersons}</h2>
          <p className='text-gray-600 mb-4'>Kirkegård: {visit.graveyard_name || 'Ingen kirkegård registrert.'}</p>
          {visit.description && (
            <p className='text-gray-600 mb-4'>Beskrivelse: {visit.description || 'Ingen beskrivelse tilgjengelig.'}</p>
          )}
          <div className='flex flex-wrap gap-2 mb-4'>
            {visit.activities.split(',').map((activity, index) => (
              <span key={index} className='bg-green-grave text-white px-2 py-1 rounded-full text-sm'>
                {activity.trim().replace('V\\u00e5rstell', 'Vårstell')}
              </span>
            ))}
          </div>
        </div>
      </Link>
    ))}
  </div>
);

export default CardView;