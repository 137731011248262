import { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance';

// Custom hook to interact with the departments API
const useDepartments = () => {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all departments
  const fetchDepartments = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get('/api/departments');
      setDepartments(response.data);
    } catch (err) {
      setError(err.message || 'Failed to fetch departments');
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch a single department by ID
  const fetchDepartmentById = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(`/api/departments/${id}`);
      return response.data;
    } catch (err) {
      setError(err.message || 'Failed to fetch department');
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Create a new department
  const createDepartment = async (departmentData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.post('/api/departments', departmentData);
      fetchDepartments(); // Refresh the department list
      return response.data;
    } catch (err) {
      setError(err.message || 'Failed to create department');
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Update an existing department
  const updateDepartment = async (id, updatedData) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.put(`/api/departments/${id}`, updatedData);
      fetchDepartments(); // Refresh the department list
      return response.data;
    } catch (err) {
      setError(err.message || 'Failed to update department');
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Add a user to a department
  const addUserToDepartment = async (departmentId, userId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.post(`/api/departments/${departmentId}/users`, { user_id: userId });
      return response.data;
    } catch (err) {
      setError(err.message || 'Failed to add user to department');
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Fetch users by department
  const fetchUsersByDepartment = async (departmentId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(`/api/departments/${departmentId}/users`);
      return response.data;
    } catch (err) {
      setError(err.message || 'Failed to fetch users for department');
      return null;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments]);

  return {
    departments,
    loading,
    error,
    fetchDepartments,
    fetchDepartmentById,
    createDepartment,
    updateDepartment,
    addUserToDepartment,
    fetchUsersByDepartment,
  };
};

export default useDepartments;