import React from 'react';
import { Link } from 'react-router-dom';

const StatBox = ({ href, bgColor, icon, count, label, children }) => (
  <Link
    to={href}
    className={`flex items-center justify-between shadow-md rounded-lg overflow-hidden ${bgColor} m-2 lg:m-1 p-5 transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-gray-400`}
    style={{
      background: `linear-gradient(135deg, ${bgColor} 0%, ${bgColor} 80%, #444)`,
    }}
  >
    <div className="flex items-center">
      <div className="text-3xl text-gray-200 mr-3">{icon}</div>
      <span className="text-lg font-semibold text-white tracking-wide text-shadow">
        {label}
      </span>
    </div>
    {children ? (
      <div className="text-lg font-semibold text-white mr-5">{children}</div>
    ) : (
      <span className="text-2xl font-extrabold text-white text-shadow-sm mr-5">
        {count}
      </span>
    )}
  </Link>
);

export default StatBox;