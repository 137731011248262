// CustomerEdit.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import DepartmentSelect from '../../components/DepartmentSelect';

function CustomerEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [postNumberError, setPostNumberError] = useState('');
  const [isFetchingPostLocation, setIsFetchingPostLocation] = useState(false);

  // Fetch customer data when component mounts
  useEffect(() => {
    fetchCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCustomer = async () => {
    try {
      const response = await axiosInstance.get(`/api/customers/${id}`);
      setCustomer(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
      setLoading(false);
    }
  };

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: fieldValue,
    }));

    // If the field is customer_post_number, fetch the corresponding post location
    if (name === 'customer_post_number') {
      if (value.length === 4) {
        setIsFetchingPostLocation(true);
        try {
          const response = await axiosInstance.get(`/api/postLocation/${parseInt(value)}`);
          if (response.data.length > 0) {
            const postLocation = response.data[0].Poststed; // Adjust based on your API response
            setCustomer((prevCustomer) => ({
              ...prevCustomer,
              customer_post_location: postLocation,
            }));
            setPostNumberError('');
          } else {
            setCustomer((prevCustomer) => ({
              ...prevCustomer,
              customer_post_location: '',
            }));
            setPostNumberError('Ugyldig postnummer');
          }
        } catch (err) {
          setCustomer((prevCustomer) => ({
            ...prevCustomer,
            customer_post_location: '',
          }));
          setPostNumberError('Ugyldig postnummer');
        } finally {
          setIsFetchingPostLocation(false);
        }
      } else {
        setPostNumberError('');
        setCustomer((prevCustomer) => ({
          ...prevCustomer,
          customer_post_location: '',
        }));
      }
    }
  };

  // Handle department selection
  const handleDepartmentChange = (departmentId) => {
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      department_id: departmentId,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(`/api/customers/${id}`, customer);
      // Optionally, display a success message
      // Redirect to customer detail page or display a success notification
      navigate(`/kunder/${id}`);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full max-w-2xl">
        {/* Header */}
        <div className="bg-green-grave text-white text-center py-6">
          <h1 className="text-3xl font-bold">Rediger Kunde</h1>
        </div>
        {/* Form */}
        <div className="px-8 py-6">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Personal Information Section */}
              <div>
                <h2 className="text-xl font-semibold text-gray-700 mb-4">
                  Personlig Informasjon
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* First Name */}
                  <div>
                    <label className="block text-gray-700">Fornavn</label>
                    <input
                      type="text"
                      name="first_name"
                      value={customer.first_name}
                      onChange={handleChange}
                      className="mt-1 w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-grave"
                      required
                    />
                  </div>
                  {/* Last Name */}
                  <div>
                    <label className="block text-gray-700">Etternavn</label>
                    <input
                      type="text"
                      name="last_name"
                      value={customer.last_name}
                      onChange={handleChange}
                      className="mt-1 w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-grave"
                      required
                    />
                  </div>
                  {/* Email */}
                  <div className="md:col-span-2">
                    <label className="block text-gray-700">E-post</label>
                    <input
                      type="email"
                      name="email"
                      value={customer.email}
                      onChange={handleChange}
                      className="mt-1 w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-grave"
                      required
                    />
                  </div>
                  {/* Phone Number */}
                  <div className="md:col-span-2">
                    <label className="block text-gray-700">Telefonnummer</label>
                    <input
                      type="text"
                      name="phone_number"
                      value={customer.phone_number || ''}
                      onChange={handleChange}
                      className="mt-1 w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-grave"
                    />
                  </div>
                </div>
              </div>

              {/* Address Section */}
              <div>
                <h2 className="text-xl font-semibold text-gray-700 mb-4">Adresse</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Address */}
                  <div className="md:col-span-2">
                    <label className="block text-gray-700">Gateadresse</label>
                    <input
                      type="text"
                      name="customer_address"
                      value={customer.customer_address || ''}
                      onChange={handleChange}
                      className="mt-1 w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-grave"
                    />
                  </div>
                  {/* Post Number */}
                  <div>
                    <label className="block text-gray-700">Postnummer</label>
                    <div className="relative">
                      <input
                        type="text"
                        name="customer_post_number"
                        value={customer.customer_post_number || ''}
                        onChange={handleChange}
                        className={`mt-1 w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 ${
                          postNumberError
                            ? 'border-red-500 focus:ring-red-500'
                            : 'focus:ring-green-grave'
                        }`}
                      />
                      {isFetchingPostLocation && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                          <svg
                            className="animate-spin h-5 w-5 text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8v8H4z"
                            ></path>
                          </svg>
                        </div>
                      )}
                    </div>
                    {postNumberError && (
                      <p className="text-red-500 text-sm mt-1">{postNumberError}</p>
                    )}
                  </div>
                  {/* Post Location */}
                  <div>
                    <label className="block text-gray-700">Poststed</label>
                    <input
                      type="text"
                      name="customer_post_location"
                      value={customer.customer_post_location || ''}
                      onChange={handleChange}
                      className="mt-1 w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-grave"
                    />
                  </div>
                </div>
                {/* Department Selection */}
              <div>
                <h2 className="text-xl font-semibold text-gray-700 mb-4">
                  Avdeling
                </h2>
                <DepartmentSelect 
                  onClick={handleDepartmentChange} 
                  value={customer.department_id || ''} 
                  className="mb-4" 
                />
              </div>
              </div>

              {/* Opt-in Section */}
              <div>
                <h2 className="text-xl font-semibold text-gray-700 mb-4">Kommunikasjon</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Opt-in Email */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="opt_in_email"
                      checked={customer.opt_in_email}
                      onChange={handleChange}
                      id="opt_in_email"
                      className="h-4 w-4 text-green-grave focus:ring-green-grave border-gray-300 rounded"
                    />
                    <label htmlFor="opt_in_email" className="ml-2 block text-gray-700">
                      Mottar e-post?
                    </label>
                  </div>
                  {/* Opt-in SMS */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="opt_in_sms"
                      checked={customer.opt_in_sms}
                      onChange={handleChange}
                      id="opt_in_sms"
                      className="h-4 w-4 text-green-grave focus:ring-green-grave border-gray-300 rounded"
                    />
                    <label htmlFor="opt_in_sms" className="ml-2 block text-gray-700">
                      Mottar SMS?
                    </label>
                  </div>
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-end space-x-4 mt-6">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="px-6 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  Avbryt
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 bg-green-grave text-white rounded-md hover:bg-orange-grave focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-grave"
                  disabled={postNumberError !== ''}
                >
                  Lagre Endringer
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomerEdit;