// components/ImageWithFallback.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ImageWithFallback = ({ src, fallbackSrc, alt, className, onClick }) => {
  const [currentSrc, setCurrentSrc] = useState(src || fallbackSrc);
  const [attemptedMainURL, setAttemptedMainURL] = useState(false);
  const [isFinalFallback, setIsFinalFallback] = useState(false);

  const mainURL = process.env.REACT_APP_MAIN_URL || "https://cms.gravstellerne.no";

  const handleError = () => {
    if (!attemptedMainURL && src) {
      setCurrentSrc(`${mainURL}${src}`);
      setAttemptedMainURL(true);
    } else if (!isFinalFallback) {
      setCurrentSrc(fallbackSrc);
      setIsFinalFallback(true);
    }
  };

  // Pass the fully resolved `currentSrc` to `onClick` when clicked
  const handleClick = () => {
    if (onClick) {
      onClick(currentSrc);
    }
  };

  return (
    <img
      src={currentSrc}
      alt={alt}
      className={className}
      onError={handleError}
      onClick={handleClick} // Call handleClick here
      loading="lazy"
    />
  );
};

ImageWithFallback.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ImageWithFallback.defaultProps = {
  src: '',
  fallbackSrc: '/images/default_grave.jpg',
  className: '',
};

export default ImageWithFallback;