import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import { useCustomer } from '../../context/CustomerContext';
import { useAdminView } from '../../context/AdminViewContext';
import axiosInstance from '../../utils/axiosInstance';

const ViewAsCustomerButton = ({ customerId }) => {
  const { login } = useCustomer();
  const { enterAdminView } = useAdminView();
  const navigate = useNavigate();

  const handleViewAsCustomer = async () => {
    try {
      const response = await axiosInstance.get(`/api/customers/${customerId}`);
      const customerData = response.data;
      
      login({
        id: customerData.id,
        name: customerData.first_name + ' ' + customerData.last_name,
        type: 'customer',
        accessToken: customerData.accessToken,
        refreshToken: customerData.refreshToken,
        email: customerData.email,
        showGravepersonPicture: customerData.show_graveperson_picture,
        firstLogin: false,
      });

      enterAdminView(); // Enter admin view mode
      navigate('/kunde'); // Redirect to the customer portal
    } catch (err) {
      console.error('Error fetching customer data:', err);
    }
  };

  return (
    <button
      onClick={handleViewAsCustomer}
      className="bg-green-grave hover:bg-green-600 text-white font-bold py-1 px-3 rounded-full inline-flex items-center transition duration-200 ease-in-out text-xs"
    >
      <FaUser className="mr-1" />
      Vis Kundeportal
    </button>
  );
};

export default ViewAsCustomerButton;