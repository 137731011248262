import React, { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext'; // Assuming useUser is a hook for accessing user details
import { AiOutlineComment, AiFillDelete } from 'react-icons/ai';

const CustomerComments = ({ customerId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loadingComments, setLoadingComments] = useState(false);
  const [commentError, setCommentError] = useState('');
  const { user } = useUser();

  const apiURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchComments = async () => {
      setLoadingComments(true);
      setCommentError('');

      try {
        const res = await fetch(`${apiURL}/api/customers/${customerId}/comments`);
        if (!res.ok) throw new Error('Failed to load comments.');

        const data = await res.json();
        setComments(data);
      } catch (error) {
        setCommentError(error.message);
      } finally {
        setLoadingComments(false);
      }
    };

    fetchComments();
  }, [customerId]);

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return; // Prevent empty comments

    try {
      const res = await fetch(`${apiURL}/api/customers/${customerId}/comments`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ comment: newComment, userId: user.id }),
      });
      if (!res.ok) throw new Error('Failed to add comment.');

      const addedComment = await res.json();
      setComments((prevComments) => [...prevComments, addedComment]);
      setNewComment(''); // Clear input after submission
    } catch (error) {
      setCommentError(error.message);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const res = await fetch(`${apiURL}/api/customers/comments/${commentId}`, {
        method: 'DELETE',
      });
      if (!res.ok) throw new Error('Failed to delete comment.');

      setComments((prevComments) =>
        prevComments.filter((comment) => comment.id !== commentId)
      );
    } catch (error) {
      setCommentError(error.message);
    }
  };

  return (
    <div className="space-y-6">
      {/* New Comment Form */}
      <div>
        <h3 className="text-lg font-semibold mb-2">Legg til ny kommentar</h3>
        <form onSubmit={handleAddComment} className="flex space-x-2">
          <input
            type="text"
            value={newComment}
            onChange={handleCommentChange}
            placeholder="Skriv din kommentar her"
            className="flex-1 px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-green-grave"
          />
          <button type="submit" className="btn btn-primary">
            Legg til
          </button>
        </form>
      </div>

      {/* Comments List */}
      {loadingComments ? (
        <p className="text-center">Laster kommentarer...</p>
      ) : comments.length > 0 ? (
        <ul className="space-y-4">
          {comments.map((comment) => (
            <li key={comment.id} className="bg-white rounded-lg shadow p-4">
              <div className="flex items-start space-x-4">
                {/* User Avatar Placeholder */}
                <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-500">
                  {comment.userName ? comment.userName.charAt(0) : 'U'}
                </div>
                <div className="flex-1">
                  <div className="flex justify-between">
                    <p className="font-medium">{comment.userName || 'Ukjent'}</p>
                    <p className="text-sm text-gray-500">
                      {new Date(comment.created_at).toLocaleString('nb-NO')}
                    </p>
                  </div>
                  <p className="mt-2">{comment.comment}</p>
                </div>
                <button
                  onClick={() => handleDeleteComment(comment.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <AiFillDelete size={20} />
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="text-center text-gray-500">
          <AiOutlineComment className="text-4xl mx-auto" />
          <p>Ingen kommentarer enda.</p>
        </div>
      )}

      {commentError && <p className="text-red-500 text-center">{commentError}</p>}
    </div>
  );
};

export default CustomerComments;