import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { useNotifications } from '../context/NotificationContext';
import axiosInstance from '../utils/axiosInstance';

export const useAuth = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { login: loginUser, logout: logoutUser, user } = useUser();
  const { addNotification } = useNotifications();
  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    // Check and restore session if token is valid
    const accessToken = localStorage.getItem('accessToken');
    const expiration = localStorage.getItem('accessTokenExpiration');
    if (accessToken && expiration && Date.now() < parseInt(expiration)) {
      const userId = localStorage.getItem('userId');
      const userName = localStorage.getItem('userName');
      const userType = localStorage.getItem('userType');
      loginUser({ id: userId, name: userName, type: userType });
      startSessionTimeout(parseInt(expiration));
    }
  }, [loginUser]);

  const handleLogin = async (email, password) => {
    try {
      const response = await axiosInstance.post(`/api/login`, { email, password });
      const data = response.data;
      const expirationTime = Date.now() + 2 * 60 * 60 * 1000;

      // Store tokens and user info in localStorage
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('accessTokenExpiration', expirationTime);
      localStorage.setItem('userId', data.userId);
      localStorage.setItem('userName', data.userName);
      localStorage.setItem('userType', data.userType);

      // Set user data and start session watcher
      loginUser({ id: data.userId, name: data.userName, type: data.userType });
      startSessionTimeout(expirationTime);

      addNotification({ message: 'Logget inn.', type: 'success' });
      navigate(from, { replace: true });
    } catch (err) {
      setError('Failed to login');
    }
  };

  const handleLogout = () => {
    logoutUser();
    localStorage.clear();
    addNotification({ message: 'You have been logged out.', type: 'warning' });
    navigate('/logginn');
  };

  const refreshToken = async () => {
    const storedRefreshToken = localStorage.getItem('refreshToken');
    try {
      const response = await axiosInstance.post(`/api/token`, { token: storedRefreshToken });
      const data = response.data;
      const newExpiration = Date.now() + 2 * 60 * 60 * 1000;

      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('accessTokenExpiration', newExpiration);

      startSessionTimeout(newExpiration);
    } catch {
      handleLogout();
    }
  };

  const startSessionTimeout = (expiration) => {
    const timeout = expiration - Date.now() - 5 * 60 * 1000;
    if (timeout > 0) setTimeout(refreshToken, timeout);
    else refreshToken();
  };

  return { handleLogin, handleLogout, error };
};