import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/nb';
import { FaClock, FaPlay, FaPause, FaTimes } from 'react-icons/fa';

moment.locale('nb');

const PunchClockMini = ({ userId }) => {
  const [currentTime, setCurrentTime] = useState(moment());
  const [punchInTime, setPunchInTime] = useState(null);
  const [isPunchedIn, setIsPunchedIn] = useState(false);
  const [comment, setComment] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(''); // 'punchIn' or 'punchOut'

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    checkPunchStatus();

    return () => clearInterval(timer);
  }, []);

  const checkPunchStatus = async () => {
    try {
      const response = await axios.get(`${apiURL}/api/logs/status/${userId}`);
      if (response.data.punchedIn) {
        setPunchInTime(moment(response.data.log.start_time));
        setIsPunchedIn(true);
      }
    } catch (error) {
      console.error('Error fetching punch status:', error);
    }
  };

  const handlePunchIn = async () => {
    try {
      const response = await axios.post(`${apiURL}/api/logs/punch-in`, {
        userId,
        loginComment: comment,
      });
      setPunchInTime(moment(response.data.start_time));
      setIsPunchedIn(true);
      setComment('');
    } catch (error) {
      console.error('Error punching in:', error);
    }
  };

  const handlePunchOut = async () => {
    try {
      await axios.post(`${apiURL}/api/logs/punch-out`, {
        userId,
        logoutComment: comment,
      });
      setPunchInTime(null);
      setIsPunchedIn(false);
      setComment('');
    } catch (error) {
      console.error('Error punching out:', error);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <FaClock className="text-gray-300 mr-2" />
          <div>
            <div className="text-sm">{currentTime.format('HH:mm')}</div> {/* Updated format */}
            {isPunchedIn && (
              <div className="text-xs text-green-400">
                {moment.utc(moment(currentTime).diff(punchInTime)).format('HH:mm:ss')}
              </div>
            )}
          </div>
        </div>
        <button
          onClick={() => {
            setModalAction(isPunchedIn ? 'punchOut' : 'punchIn');
            setIsModalOpen(true);
          }}
          className={`p-2 rounded-full ${
            isPunchedIn ? 'bg-red-500' : 'bg-green-grave'
          } text-white`}
        >
          {isPunchedIn ? <FaPause /> : <FaPlay />}
        </button>
      </div>

      {/* Modal for Comments */}
{isModalOpen && (
  <div
    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    onClick={() => {
      setIsModalOpen(false);
      setComment('');
    }}
  >
    <div
      className="bg-white rounded-lg shadow-lg w-11/12 max-w-md mx-auto p-6 relative"
      onClick={(e) => e.stopPropagation()}
    >
      {/* Close Button */}
      <button
        onClick={() => {
          setIsModalOpen(false);
          setComment('');
        }}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
      >
        <FaTimes size={20} />
      </button>
      <h2 className="text-xl font-semibold mb-4 text-gray-800">
        {modalAction === 'punchIn' ? 'Start' : 'Stopp'} Timelogg
      </h2>
      <textarea
        id="commentTextarea"
        className="w-full text-gray-800 h-24 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        placeholder="Kommentar (valgfritt)"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <div className="mt-4 flex justify-end space-x-3">
        <button
          onClick={() => {
            setIsModalOpen(false);
            setComment('');
          }}
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition"
        >
          Avbryt
        </button>
        <button
          onClick={() => {
            if (modalAction === 'punchIn') {
              handlePunchIn();
            } else {
              handlePunchOut();
            }
            setIsModalOpen(false);
            setComment('');
          }}
          className={`px-4 py-2 rounded-md text-white transition ${
            modalAction === 'punchIn' ? 'bg-green-grave hover:bg-green-800' : 'bg-red-500 hover:bg-red-600'
          }`}
        >
          {modalAction === 'punchIn' ? 'Start' : 'Stopp'}
        </button>
      </div>
    </div>
  </div>
)}
    </>
  );
};

export default PunchClockMini;