import React from 'react';
import useDepartments from '../hooks/useDepartments';

const DepartmentSelect = ({ onClick, value, className }) => {
  const { departments, loading, error } = useDepartments();

  return (
    <div className={className}>
      {loading && <p>Loading departments...</p>}
      {error && <p>{error}</p>}
      <select 
        onChange={(e) => onClick(e.target.value)} 
        value={value} 
        className="p-2 border border-gray-300 rounded-lg w-full"
      >
        <option value="">Select a Department</option>
        {departments.map((department) => (
          <option key={department.id} value={department.id}>
            {department.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DepartmentSelect;