import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import CustomerComments from '../../components/customers/CustomerComments';
import GravestonesListCustomer from '../../components/customers/GravestonesListCustomer';
import LogViewer from '../../components/customers/LogViewer';
import ContactsManager from '../../components/customers/ContactsManager';
import LoadingScreen from '../../components/LoadingScreen';
import CustomerPasswordUpdate from '../../components/modals/CustomerPasswordUpdate';
import ViewAsCustomerButton from '../../components/admin-components/ViewAsCustomerButton';
import VisitComment from '../../components/VisitComment'; // Import VisitComment component
import { AiOutlineMail, AiFillPhone, AiFillHome } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';

function CustomerDetails() {
  const { id } = useParams();
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const apiURL = useMemo(() => process.env.REACT_APP_API_BASE_URL || '', []);

  const fetchCustomerDetails = useCallback(async () => {
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(`${apiURL}/api/customers/${id}`);
      if (!response.ok) throw new Error('Failed to load customer details.');
      const data = await response.json();

      setCustomer(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [apiURL, id]);

  useEffect(() => {
    fetchCustomerDetails();
  }, [fetchCustomerDetails]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (isLoading) return <LoadingScreen />;
  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;
  if (!customer) return <div className="text-center">No customer data found.</div>;

  return (
    <div className="max-w-full mx-auto p-6">
      {/* Main Grid Container */}
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
        {/* Left Column */}
        <div className="lg:col-span-4 space-y-6">
          {/* Customer Info */}
<div className="bg-white shadow rounded-lg p-6">
  <div className="flex items-center space-x-4">
    {/* Profile Placeholder */}
    <div className="h-16 w-16 rounded-full bg-green-500 flex items-center justify-center text-white text-xl font-bold">
      {customer.first_name.charAt(0)}
      {customer.last_name.charAt(0)}
    </div>
    <div>
    <div className="flex items-center">
  <h2 className="font-bold text-2xl mr-2">
    {customer.first_name} {customer.last_name}
  </h2>
  <Link
    to={`/kunder/${customer.id}/endre`}
    className="text-gray-500 hover:text-gray-700"
    title="Rediger kunde"
  >
    <FaEdit size={20} />
  </Link>
</div>
<p className="text-gray-600">#{customer.customer_number || 'Ingen nummer'}</p>
    </div>
  </div>

  <div className="mt-6 space-y-4">
    {/* Contact Information */}
    {customer.email && (
      <div className="flex items-center space-x-2">
        <AiOutlineMail className="text-gray-500" />
        <span>{customer.email}</span>
      </div>
    )}
    {customer.phone_number && (
      <div className="flex items-center space-x-2">
        <AiFillPhone className="text-gray-500" />
        <span>{customer.phone_number}</span>
      </div>
    )}
    {customer.customer_address && (
  <div className="flex items-start space-x-2">
    <AiFillHome className="text-gray-500 mt-1" />
    <div>
      <p>{customer.customer_address}</p>
      {(customer.customer_post_number || customer.customer_post_location) && (
        <p>
          {customer.customer_post_number} {customer.customer_post_location}
        </p>
      )}
    </div>
  </div>
)}
  </div>

  <div className="mt-6 flex space-x-4">
    <button
      onClick={openModal}
      className="bg-green-grave text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
    >
      Endre Passord
    </button>
    <ViewAsCustomerButton customerId={customer.id} />
  </div>
</div>

          {/* Contacts */}
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-semibold text-green-grave mb-4">Kontakter</h3>
            <ContactsManager customerId={customer.id} />
          </div>

          {/* Internal Comments */}
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-semibold text-green-grave mb-4">Interne Kommentarer</h3>
            <CustomerComments customerId={customer.id} />
          </div>
        </div>

        {/* Right Column */}
        <div className="lg:col-span-8 space-y-6">
          {/* Gravestones */}
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-semibold text-green-grave mb-4">Gravsteiner</h3>
            <GravestonesListCustomer fetchId={customer.id} queryParam="customer_id" showAddNewGravestone={true} showSearch={true} />
          </div>

          {/* Visit Comments */}
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-semibold text-green-grave mb-4">Tilbakemeldinger</h3>
            <VisitComment customer_id={customer.id} showAllFromCustomer={true} showVisitLink={true} readOnly={true} />
          </div>

          {/* Logs */}
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-semibold text-green-grave mb-4">Logger</h3>
            {/* LogViewer Component */}
          </div>
        </div>
      </div>

      {/* Password Modal */}
      {isModalOpen && (
        <CustomerPasswordUpdate customerId={customer.id} onClose={closeModal} />
      )}
    </div>
  );
}

export default CustomerDetails;