// src/hooks/useDisableBodyScroll.js
import { useEffect } from 'react';

export const useDisableBodyScroll = (isModalOpen) => {
  useEffect(() => {
    if (isModalOpen) {
      // Prevent body from scrolling
      document.body.style.overflow = 'hidden';
      // Prevent touch events
      document.body.style.touchAction = 'none';
    } else {
      // Re-enable body scrolling
      document.body.style.overflow = '';
      document.body.style.touchAction = '';
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = '';
      document.body.style.touchAction = '';
    };
  }, [isModalOpen]);
};