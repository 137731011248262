import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import useInitiateVisit from "../../hooks/useInitiateVisit";
import ImageModal from "../../components/ImageModal";
import Modal from "../../components/ReusableModal";
import GravestoneMapAll from "../../components/GravestoneMapAll";
import WorkorderGravestoneCard from "../../components/WorkorderGravestoneCard";
import LoadingScreen from "../../components/LoadingScreen";
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { getDistance } from "geolib";
import {
  AiOutlineCalendar,
  AiOutlineUser,
  AiOutlineGlobal,
} from "react-icons/ai";

const getValidGravestonesWithDistance = (gravestones, userLocation) => {
  // Ensure gravestones is always an array
  const gravestonesArray = Array.isArray(gravestones) ? gravestones : [];

  const results = gravestonesArray.reduce(
    (acc, gravestone) => {
      let distance = Number.MAX_VALUE; // Default high distance for invalid locations

      if (
        gravestone.latitude &&
        gravestone.longitude &&
        gravestone.latitude !== "NULL" &&
        gravestone.longitude !== "NULL"
      ) {
        const gravestoneLocation = {
          latitude: parseFloat(gravestone.latitude),
          longitude: parseFloat(gravestone.longitude),
        };
        if (userLocation && userLocation.latitude && userLocation.longitude) {
          distance = getDistance(userLocation, gravestoneLocation);
        }
      }

      const gravestoneWithDistance = { ...gravestone, distance };

      // Separate finished and remaining gravestones
      if (Number(gravestone.visitStatusId) === 3) {
        acc.finishedGravestones.push(gravestoneWithDistance);
      } else {
        acc.remainingGravestones.push(gravestoneWithDistance);
      }

      return acc;
    },
    { finishedGravestones: [], remainingGravestones: [] }
  );

  // Sort remainingGravestones by distance
  results.remainingGravestones.sort((a, b) => a.distance - b.distance);

  return results;
};

const WorkOrderDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [workorder, setWorkorder] = useState(null);
  const [gravestones, setGravestones] = useState([]);
  const [finishedGravestones, setFinishedGravestones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [userLocation, setUserLocation] = useState(null);
  const [activeTab, setActiveTab] = useState("remaining");
  const initiateVisit = useInitiateVisit();
  const [uniqueGraveyardPictures, setUniqueGraveyardPictures] = useState([]);
  const { user } = useUser();
  const apiURL = process.env.REACT_APP_API_BASE_URL || "";
  const baseURL = "https://cms.gravstellerne.no";

  useDisableBodyScroll(isMapModalOpen);

  const switchTab = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    // Watch position changes
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        setUserLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {},
      { enableHighAccuracy: true, maximumAge: 10000, timeout: 5000 }
    );

    // Clean up function to stop watching position when component unmounts or re-renders
    return () => navigator.geolocation.clearWatch(watchId);
  }, []);

  useEffect(() => {
    const fetchWorkOrderDetails = async () => {
      try {
        const response = await fetch(`${apiURL}/api/workorders/${id}`);
        const data = await response.json();
        if (!response.ok)
          throw new Error(data.message || "Could not fetch workorder details");

        setWorkorder(data);

        // Now data.gravestones contains the gravestones array
        const { finishedGravestones, remainingGravestones } =
          getValidGravestonesWithDistance(data.gravestones, userLocation);

        setGravestones(remainingGravestones);
        setFinishedGravestones(finishedGravestones);

        // Check if remainingGravestones is empty and switch to finished tab
        if (remainingGravestones.length === 0) {
          setActiveTab("finished");
        }

        // Extract unique graveyard pictures
        const graveyardPictures = data.gravestones
          .map((item) => item.graveyard_picture)
          .filter(Boolean); // Get valid picture URLs
        const uniquePictures = [...new Set(graveyardPictures)]; // Get unique pictures
        setUniqueGraveyardPictures(uniquePictures); // Store unique pictures in state
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkOrderDetails();
  }, [id, userLocation, apiURL]);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "1":
        return "text-green-500"; // Green for "Ready"
      case "2":
        return "text-yellow-500"; // Yellow for "In Progress"
      case "3":
        return "text-blue-500"; // Blue for "Completed"
      default:
        return "text-gray-500"; // Gray for unknown or other statuses
    }
  };

  const toggleMapModal = () => setIsMapModalOpen(!isMapModalOpen);

  if (loading) return <LoadingScreen />;
  if (error) return <div>Error: {error}</div>;
  if (!workorder) return <div>No workorder found</div>;

  const displayedGravestones =
    activeTab === "remaining"
      ? gravestones
      : activeTab === "finished"
      ? finishedGravestones
      : [];

  return (
    <>
      {/* Top Content with Compact Design */}
      <div className="p-2">
        {/* Header Card */}
        <div className="bg-white rounded-md shadow p-3 mb-4">
          <h1 className="text-lg font-bold mb-2">
            {workorder.name}{" "}
            <span className="text-gray-500">#{workorder.id}</span>
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
            {/* Start Date */}
            <div className="flex items-center">
              <AiOutlineCalendar className="text-green-500 mr-1" size={18} />
              <div>
                <p className="text-xs text-gray-500">Startdato</p>
                <p className="text-sm font-medium">
                  {new Date(workorder.work_date).toLocaleDateString("nb-NO")}
                </p>
              </div>
            </div>
            {/* Due Date */}
            <div className="flex items-center">
              <AiOutlineCalendar className="text-red-500 mr-1" size={18} />
              <div>
                <p className="text-xs text-gray-500">Frist</p>
                <p className="text-sm font-medium">
                  {workorder.due_date
                    ? new Date(workorder.due_date).toLocaleDateString("nb-NO")
                    : "N/A"}
                </p>
              </div>
            </div>
            {/* Workers */}
            <div className="flex items-center">
              <AiOutlineUser className="text-blue-500 mr-1" size={18} />
              <div>
                <p className="text-xs text-gray-500">Arbeidere</p>
                <p className="text-sm font-medium break-words">
                  {workorder.users.map((u) => u.name).join(", ")}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Tab Navigation */}
        <div className="flex flex-wrap justify-between items-center mb-3">
          <div className="flex flex-wrap space-x-1">
            <button
              className={`px-3 py-1 text-sm font-semibold rounded ${
                activeTab === "remaining"
                  ? "bg-green-600 text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
              onClick={() => switchTab("remaining")}
            >
              Gravsteiner
            </button>
            <button
              className={`px-3 py-1 text-sm font-semibold rounded ${
                activeTab === "finished"
                  ? "bg-green-600 text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
              onClick={() => switchTab("finished")}
            >
              Ferdige
            </button>
            <button
              className={`px-3 py-1 text-sm font-semibold rounded ${
                activeTab === "map"
                  ? "bg-green-600 text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
              onClick={() => switchTab("map")}
            >
              Kart
            </button>
          </div>
          <button
            onClick={toggleMapModal}
            className="hover:bg-gray-200 transition-colors pr-2"
            aria-label="Vis kart"
          >
            <AiOutlineGlobal size={30} />
          </button>
        </div>
      </div>

      {/* Display based on active tab */}
      {activeTab === "map" ? (
        <div>
          <h3 className="text-lg font-bold mb-4">Gravstedskart</h3>
          {uniqueGraveyardPictures.map((picture, index) => (
            <img
              key={index}
              src={`${baseURL}${picture}`}
              alt={`Gravstedskart ${index + 1}`}
              className="w-full h-auto rounded shadow-lg mb-4"
            />
          ))}
        </div>
      ) : (
        <div className="">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 sm:p-2 gap-0.5 sm:gap-2">
            {displayedGravestones
              .sort((a, b) => {
                if (
                  Number(a.visitStatusId) === 2 &&
                  Number(b.visitStatusId) !== 2
                )
                  return -1;
                if (
                  Number(b.visitStatusId) === 2 &&
                  Number(a.visitStatusId) !== 2
                )
                  return 1;
                return 0;
              })
              .map((gravestone, index) => (
                <WorkorderGravestoneCard
                  key={index}
                  gravestone={gravestone}
                  apiURL={apiURL}
                  openModal={openModal}
                  getStatusColor={getStatusColor}
                  initiateVisit={(gravestoneId) =>
                    initiateVisit(gravestoneId, id)
                  }
                />
              ))}
          </div>
        </div>
      )}

      {/* Modals */}
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageSrc={selectedImage}
      />
      <Modal isOpen={isMapModalOpen} close={toggleMapModal}>
        <GravestoneMapAll
          gravestones={workorder.gravestones}
          workorder_id={id}
        />
      </Modal>
    </>
  );
};

export default WorkOrderDetailsPage;