import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { handleLogin, error } = useAuth();

  const onSubmit = async (e) => {
    e.preventDefault();
    await handleLogin(email, password);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full max-w-md">
        {/* Header Section */}
        <div className="bg-white text-center py-8">
          <img
            className="mx-auto px-5"
            src="/images/logo_login.png"
            alt="Gravstellerne Logo"
          />
        </div>
  
        {/* Form Section */}
        <div className="px-8 pb-8">
          <h2 className="text-center text-2xl font-bold text-gray-800 mb-6">
            Logg Inn
          </h2>
          {error && (
            <p className="text-center text-red-500 text-sm italic mb-4">{error}</p>
          )}
          <form onSubmit={onSubmit} className="space-y-5">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                E-post
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full px-4 py-3 bg-gray-50 border border-gray-300
                  rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2
                  focus:ring-green-grave focus:border-green-grave"
                placeholder="Din e-postadresse"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Passord
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="mt-1 block w-full px-4 py-3 bg-gray-50 border border-gray-300
                  rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2
                  focus:ring-green-grave focus:border-green-grave"
                placeholder="Ditt passord"
              />
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-3 px-4 border border-transparent
                  rounded-md shadow-sm text-lg font-medium text-white bg-green-grave
                  hover:bg-orange-grave focus:outline-none focus:ring-2 focus:ring-offset-2
                  focus:ring-green-grave transition-colors duration-200"
              >
                Logg inn
              </button>
            </div>
            <div className="text-center mt-4">
              <a
                href="#"
                className="text-sm font-medium text-green-grave hover:text-orange-grave"
              >
                Glemt passord?
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;