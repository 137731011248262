import React, { useState, useMemo } from 'react';
import { FaChevronDown, FaChevronUp, FaBell } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AiOutlineLike, AiOutlineDislike } from 'react-icons/ai';
import Rating from './Rating';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';

const VisitCommentsTable = ({ comments, onCommentClick }) => {
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRowExpansion = (key) => {
    setExpandedRows((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Memoize grouped comments to prevent unnecessary recalculations
  const groupedComments = useMemo(() => {
    // Group comments by visit_id and visit_type
    const grouped = comments.reduce((acc, comment) => {
      const key = `${comment.visit_id}-${comment.visit_type}`;
      if (!acc[key]) acc[key] = [];
      acc[key].push(comment);
      return acc;
    }, {});

    // Process each group
    return Object.entries(grouped).map(([key, group]) => {
      // Sort the group by created_at
      const sortedGroup = [...group].sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );

      const mainComment = sortedGroup[0];
      const replies = sortedGroup.slice(1);

      const hasUnreadReplies = replies.some((reply) => reply.seen_by_user === 0);

      return {
        key,
        mainComment: { ...mainComment, hasUnreadReplies },
        replies,
      };
    });
  }, [comments]);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white shadow rounded-lg">
        <thead className="bg-green-grave text-white">
          <tr>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
              Dato
            </th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
              Kommentar
            </th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
              Kunde
            </th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
              Gravpersoner
            </th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
              Besøk
            </th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
              Vurdering
            </th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
              Besøkt
            </th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
              Sett
            </th>
            <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
              Svar
            </th>
          </tr>
        </thead>
        <tbody className="text-gray-700">
          {groupedComments.map((group, rowIndex) => {
            const { key, mainComment, replies } = group;
            const isExpanded = expandedRows[key];

            return (
              <React.Fragment key={key}>
                {/* Main Comment Row */}
                <tr
                  className={`hover:bg-gray-100 ${rowIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                  onClick={() => onCommentClick(mainComment)}
                >
                  <td className="px-4 py-3 text-sm">
                    {new Date(mainComment.created_at).toLocaleString('nb-NO')}
                  </td>
                  <td className="px-4 py-3 text-sm flex items-center">
                    {mainComment.comment}
                    {/* Expand/Collapse Button */}
                    {replies.length > 0 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleRowExpansion(key);
                        }}
                        className="ml-2 text-green-grave hover:text-orange-grave"
                      >
                        {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
                      </button>
                    )}
                    {/* Notification Icon */}
                    {mainComment.hasUnreadReplies && (
                      <FaBell className="ml-2 text-red-500" title="Uleste svar" />
                    )}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <Link
                      to={`/kunder/${mainComment.customer_id}`}
                      className="text-green-grave hover:text-orange-grave underline"
                    >
                      {mainComment.customer_name}
                    </Link>
                  </td>
                  <td className="px-4 py-3 text-sm">
                    {/* Display the gravepersons connected to the gravestone */}
                    {mainComment.gravepersons?.length > 0 ? (
                      <div className="space-y-2">
                        {mainComment.gravepersons.map((person) => (
                          <div key={person.id} className="text-sm">
                            <Link
                              to={`/gravsteiner/${mainComment.gravestone_id}`}
                              className="font-semibold text-green-grave hover:text-orange-grave underline"
                            >
                              {person.name}
                            </Link>
                            <div className="text-gray-600 text-xs">
                              {person.birthdate
                                ? new Date(person.birthdate).toLocaleDateString('nb-NO')
                                : '??'}{' '}
                              -{' '}
                              {person.deathdate
                                ? new Date(person.deathdate).toLocaleDateString('nb-NO')
                                : '??'}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <span className="text-gray-500">Ingen gravpersoner</span>
                    )}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <Link
                      to={`/besok/${mainComment.visit_id}/detaljer/${
                        mainComment.visit_type === 'visits' ? '1' : '0'
                      }`}
                      className="text-green-grave underline hover:text-orange-grave"
                    >
                      {mainComment.visit_id}
                    </Link>
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <Rating
                      visit_type={mainComment.visit_type}
                      visit_id={mainComment.visit_id}
                      gravestone_id={mainComment.gravestone_id}
                      customer_id={mainComment.customer_id}
                      readOnly={true}
                    />
                  </td>
                  <td className="px-4 py-3 text-sm">
                    {new Date(mainComment.visit_date).toLocaleString('nb-NO')}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    {mainComment.seen_by_user ? (
                      <AiOutlineLike className="text-green-grave text-xl" />
                    ) : (
                      <AiOutlineDislike className="text-red-500 text-xl" />
                    )}
                  </td>
                  <td className="px-4 py-3 text-md">
                    {replies.length > 0 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleRowExpansion(key);
                        }}
                        className="text-green-grave hover:text-orange-grave underline"
                      >
                        {isExpanded ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    )}
                  </td>
                </tr>
                {/* Reply Rows */}
                {isExpanded &&
                  replies.map((reply, replyIndex) => (
                    <tr
                      key={reply.id}
                      className={`hover:bg-gray-100 ${
                        (rowIndex + replyIndex + 1) % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                      }`}
                      onClick={() => onCommentClick(reply)}
                    >
                      <td className="px-4 py-3 text-sm">
                        {new Date(reply.created_at).toLocaleString('nb-NO')}
                      </td>
                      <td className="px-4 py-3 text-sm" colSpan="4">
                        {reply.comment}
                      </td>
                      <td className="px-4 py-3 text-sm" colSpan="2">
                        {reply.user_name ? (
                          <Link
                            to={`/brukere/${reply.user_id}`}
                            className="text-green-grave hover:text-orange-grave underline"
                          >
                            {reply.user_name}
                          </Link>
                        ) : (
                          <Link
                            to={`/kunder/${reply.customer_id}`}
                            className="text-green-grave hover:text-orange-grave underline"
                          >
                            {reply.customer_name}
                          </Link>
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm">
                        {reply.user_name ? (
                          reply.seen_by_customer ? (
                            <AiOutlineLike className="text-green-grave text-xl" />
                          ) : (
                            <AiOutlineDislike className="text-red-500 text-xl" />
                          )
                        ) : reply.seen_by_user ? (
                          <AiOutlineLike className="text-green-grave text-xl" />
                        ) : (
                          <AiOutlineDislike className="text-red-500 text-xl" />
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm"></td>
                    </tr>
                  ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VisitCommentsTable;