import React, { useState, useEffect } from 'react';
import { MdOutlineContactPage } from 'react-icons/md';
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance';

const ContactsManager = ({ customerId }) => {
  const [contacts, setContacts] = useState([]);
  const [currentContact, setCurrentContact] = useState({ first_name: '', last_name: '', phone_number: '+47', email: '' });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchContacts();
  }, [customerId]);

  const fetchContacts = async () => {
    try {
      const response = await axiosInstance.get(`/api/customer_contacts/${customerId}`);
      setContacts(response.data);
    } catch (error) {
      console.error('Failed to fetch contacts', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentContact({ ...currentContact, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      first_name: currentContact.first_name,
      last_name: currentContact.last_name,
      phone_number: currentContact.phone_number,
      email: currentContact.email,
      customer_id: customerId
    };

    try {
      if (isEditing) {
        await axiosInstance.put(`/api/customer_contacts/${currentContact.id}`, data);
      } else {
        await axiosInstance.post(`/api/customer_contacts`, data);
      }
      setCurrentContact({ first_name: '', last_name: '', phone_number: '', email: '' });
      setIsEditing(false);
      fetchContacts();
    } catch (error) {
      console.error('Failed to submit contact', error);
    }
  };

  const startEditing = (contact) => {
    setCurrentContact(contact);
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setCurrentContact({ first_name: '', last_name: '', phone_number: '', email: '' });
    setIsEditing(false);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/api/customer_contacts/${id}`);
      fetchContacts();
    } catch (error) {
      console.error('Failed to delete contact', error);
    }
  };

  return (
    <div className="space-y-6">
      {/* Contact Form */}
      <div className="bg-gray-50 rounded-lg p-4">
        <h4 className="text-lg font-semibold mb-4">
          {isEditing ? 'Oppdater Kontakt' : 'Legg til Kontakt'}
        </h4>
        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Input Fields */}
          <input
            className="input input-bordered w-full"
            name="first_name"
            value={currentContact.first_name}
            onChange={handleInputChange}
            placeholder="Fornavn"
            required
          />
          <input
            className="input input-bordered w-full"
            name="last_name"
            value={currentContact.last_name}
            onChange={handleInputChange}
            placeholder="Etternavn"
            required
          />
          <input
            className="input input-bordered w-full md:col-span-2"
            name="phone_number"
            value={currentContact.phone_number}
            onChange={handleInputChange}
            placeholder="Telefonnummer"
          />
          <input
            className="input input-bordered w-full md:col-span-2"
            name="email"
            value={currentContact.email}
            onChange={handleInputChange}
            placeholder="E-post"
          />
          <div className="flex justify-end md:col-span-2 space-x-2">
            <button type="submit" className="btn btn-primary">
              {isEditing ? 'Oppdater' : 'Legg Til'}
            </button>
            {isEditing && (
              <button onClick={cancelEditing} className="btn btn-secondary">
                Avbryt
              </button>
            )}
          </div>
        </form>
      </div>

      {/* Contacts List */}
      <div>
        {contacts.length > 0 ? (
          <ul className="space-y-4">
            {contacts.map((contact) => (
              <li key={contact.id} className="bg-white rounded-lg shadow p-4 flex justify-between items-center">
                <div>
                  <p className="font-medium">
                    {contact.first_name} {contact.last_name}
                  </p>
                  {contact.phone_number && <p className="text-sm text-gray-600">{contact.phone_number}</p>}
                  {contact.email && <p className="text-sm text-gray-600">{contact.email}</p>}
                </div>
                <div className="flex space-x-2">
                  <button onClick={() => startEditing(contact)} className="btn btn-sm btn-warning">
                    Endre
                  </button>
                  <button onClick={() => handleDelete(contact.id)} className="btn btn-sm btn-error">
                    Slett
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center">
            <MdOutlineContactPage className="mx-auto text-4xl text-gray-400" />
            <p className="mt-2 text-gray-700">Ingen Kontakter</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactsManager;