// src/utils/parseMarkdownLinks.js
import React from 'react';

const parseMarkdownLinks = (text) => {
  const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
  const parts = [];
  let lastIndex = 0;

  text.replace(linkRegex, (match, linkText, url, offset) => {
    if (offset > lastIndex) {
      parts.push(text.substring(lastIndex, offset)); // Add text before the link
    }
    parts.push(
      <a key={offset} href={url} target="_blank" rel="noopener noreferrer" className="underline text-gray-200">
        {linkText}
      </a>
    );
    lastIndex = offset + match.length;
  });

  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex)); // Add remaining text after the last link
  }

  return parts;
};

export default parseMarkdownLinks;