import React from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import classNames from 'classnames';

const SidebarProfile = ({ user, isOpen, profilePath }) => {
  // Function to determine font size based on name length
  const getNameFontSize = (name) => {
    if (!name) return 'text-md';
    const length = name.length;
    if (length <= 15) return 'text-md'; // Default size
    if (length <= 20) return 'text-sm'; // Slightly smaller
    return 'text-xs'; // Smallest size
  };

  return (
    <div
      className={classNames(
        'rounded-lg shadow',
        {
          'p-2 bg-gray-700': isOpen,
          'p-1 bg-gray-800': !isOpen,
        }
      )}
    >
      <div className="flex items-center justify-between">
        {/* Left Side: User Icon and Name */}
        <div className="flex items-center">
          <Link
            to={profilePath}
            className="text-white hover:text-green-grave transition duration-150 ease-in-out"
          >
            <FaUserCircle className={isOpen ? 'text-3xl' : 'text-xl'} />
          </Link>
          {isOpen && (
            <Link
              to={profilePath}
              className="ml-2 text-white hover:text-green-grave transition duration-150 ease-in-out"
            >
              <div
                className={classNames(
                  'font-medium truncate overflow-hidden whitespace-nowrap',
                  getNameFontSize(user?.name),
                  'max-w-[120px]' // Adjusted for space
                )}
              >
                {user?.name}
              </div>
              <div className="text-xs">
                {user?.type === 'worker' ? 'Arbeider' : 'Admin'}
              </div>
            </Link>
          )}
        </div>
        {/* Right Side: Logout Icon */}
        <Link
          to="/loggut"
          className="text-white hover:text-red-500 transition duration-150 ease-in-out"
        >
          <FaSignOutAlt className={isOpen ? 'text-2xl' : 'text-xl'} />
        </Link>
      </div>
    </div>
  );
};

export default SidebarProfile;