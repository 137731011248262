import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { FaBirthdayCake, FaCross, FaMountain } from "react-icons/fa";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import LoadingScreen from "../../components/LoadingScreen";
import BackButton from "../../components/BackButton";

const GravepersonDetail = () => {
  const { id } = useParams(); // Using react-router to get the graveperson ID from the URL
  const [graveperson, setGraveperson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  useEffect(() => {
    const fetchGraveperson = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/gravepersons/${id}`);
        setGraveperson(response.data);
      } catch (err) {
        setError("Failed to fetch graveperson details.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchGraveperson();
  }, [id]);

  if (loading) return <LoadingScreen />;
  if (error)
    return <div className="text-center text-red-500 p-10">{error}</div>;

  // Prepare picture URL
  const pictureUrl = graveperson.picture
    ? `${graveperson.picture}`
    : null;

  return (
    <div className="container mx-auto p-4 mt-8 sm:mt-0">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        {/* Header Section with Picture */}
        <div className="flex flex-col sm:flex-row items-center px-4 py-5 sm:px-6">
          {pictureUrl && (
            <img
              src={pictureUrl}
              alt={`${graveperson.first_name} ${graveperson.last_name}`}
              className="w-32 h-32 object-cover rounded-full mr-4 mb-4 sm:mb-0"
            />
          )}
          <div>
            <h3 className="text-2xl leading-6 font-semibold text-gray-900">
              {graveperson.first_name} {graveperson.last_name}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Detaljer om gravperson og relatert informasjon.
            </p>
          </div>
        </div>
        {/* Details Section */}
        <div className="border-t border-gray-200">
          <dl>
            {/* Birthdate and Deathdate */}
            <div className="bg-gray-50 px-4 py-5 grid grid-cols-1 sm:grid-cols-2 gap-4 sm:px-6">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Født</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <FaBirthdayCake className="inline mr-2 text-green-500" />
                  {graveperson.birthdate
                    ? new Date(graveperson.birthdate).toLocaleDateString(
                        "nb-NO"
                      )
                    : "Ukjent"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Døde</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <FaCross className="inline mr-2 text-red-500" />
                  {graveperson.deathdate
                    ? new Date(graveperson.deathdate).toLocaleDateString(
                        "nb-NO"
                      )
                    : "Ukjent"}
                </dd>
              </div>
            </div>
            {/* Graveyard and Gravestone */}
            <div className="bg-white px-4 py-5 grid grid-cols-1 sm:grid-cols-2 gap-4 sm:px-6">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Kirkegård</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <Link
                    to={`/kirkegarder/${graveperson.graveyard_id}`}
                    className="flex items-center text-blue-600 hover:underline"
                  >
                    <FaMountain className="mr-2" />
                    {graveperson.graveyard_name || "Ukjent"}
                  </Link>
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Gravstein</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <Link
                    to={`/gravsteiner/${graveperson.gravestone_id}`}
                    className="flex items-center text-blue-600 hover:underline"
                  >
                    {graveperson.gravestone_id
                      ? `${graveperson.gravestone_id}`
                      : "Ukjent"}
                  </Link>
                </dd>
              </div>
            </div>
            {/* Minneside and Dødsannonse URLs */}
            {(graveperson.minneside_url || graveperson.dodsannonse_url) && (
              <div className="bg-gray-50 px-4 py-5 grid grid-cols-1 sm:grid-cols-2 gap-4 sm:px-6">
                {graveperson.minneside_url && (
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Minneside
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <a
                        href={graveperson.minneside_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        Åpne minneside
                      </a>
                    </dd>
                  </div>
                )}
                {graveperson.dodsannonse_url && (
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Dødsannonse
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <a
                        href={graveperson.dodsannonse_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        Åpne dødsannonse
                      </a>
                    </dd>
                  </div>
                )}
              </div>
            )}
            {/* Customer Information */}
            <div className="bg-white px-4 py-5 grid grid-cols-1 sm:grid-cols-2 gap-4 sm:px-6">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Kunde</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <Link
                    to={`/kunder/${graveperson.customer_id}`}
                    className="flex items-center text-blue-600 hover:underline"
                  >
                    {graveperson.customer_first_name}{" "}
                    {graveperson.customer_last_name}
                  </Link>
                </dd>
              </div>
              {(graveperson.customer_email || graveperson.customer_phone) && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Kundes Kontakt
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {graveperson.customer_email && (
                      <div>
                        <AiOutlineMail className="inline mr-2" />
                        {graveperson.customer_email}
                      </div>
                    )}
                    {graveperson.customer_phone && (
                      <div>
                        <AiOutlinePhone className="inline mr-2" />
                        {graveperson.customer_phone}
                      </div>
                    )}
                  </dd>
                </div>
              )}
            </div>
            {/* Description */}
            {graveperson.description && (
              <div className="bg-gray-50 px-4 py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Beskrivelse
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {graveperson.description}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
      <div className="mt-4">
        <BackButton />
      </div>
    </div>
  );
};

export default GravepersonDetail;