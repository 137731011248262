import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { useUser } from "../../context/UserContext";
import {
  FaBirthdayCake,
  FaCross,
  FaPlusCircle,
  FaMapMarkerAlt,
  FaGlobe,
  FaThList,
  FaBox,
  FaExclamationTriangle,
  FaCogs,
  FaCalendarAlt,
  FaChurch,
  FaUser,
} from "react-icons/fa";
import useInitiateVisit from "../../hooks/useInitiateVisit";
import LoadingScreen from "../../components/LoadingScreen";
import ImageWithFallback from "../../components/ImageWithFallback";
import DetailCard from "../../components/DetailCard";
import PersonCard from "../../components/customer-components/PersonCard";
import GravestoneContactsManager from "../../components/customer-components/GravestoneContactsManager";

const GravestoneProfile = () => {
  const { id } = useParams(); // ID of the gravestone
  const [visits, setVisits] = useState([]);
  const [gravestone, setGravestone] = useState(null);
  const [activeTab, setActiveTab] = useState("info"); // 'info' for gravestone information, 'visits' for visits timeline
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [width, setWidth] = useState(75);

  const initiateVisit = useInitiateVisit();

  const mainURL = process.env.REACT_APP_MAIN_URL || "";

  const { user } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        // Fetch gravestone details
        const gravestoneResponse = await axiosInstance.get(
          `/api/gravestones/${id}`
        );
        setGravestone(gravestoneResponse.data);

        // Fetch visits
        const visitsResponse = await axiosInstance.get(
          `/api/visits/gravestone/${id}`
        );
        setVisits(visitsResponse.data.visits);
      } catch (err) {
        console.error("Failed to load gravestone details or visits:", err);
        setError("Kunne ikke laste inn data. Vennligst prøv igjen senere.");
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  // Function to render gravestone information
const renderGravestoneInfo = () => (
  <div className="">
    <div className="bg-white shadow-lg rounded-lg overflow-hidden grid grid-cols-1 lg:grid-cols-3 gap-6 p-6">
      {/* Image Section */}
      <div className="flex flex-col items-center lg:col-span-1">
        <ImageWithFallback
          src={gravestone?.picture}
          mainURL={mainURL}
          fallbackSrc="/images/default_grave.jpg"
          alt="Gravsteinsbilde"
          className="w-full h-auto rounded-lg"
        />
        {user?.type === "admin" && (
          <div className="flex space-x-4 mt-4">
            <Link
              to={`/gravsteiner/${gravestone?.id}/endre`}
              className="bg-green-grave text-white px-4 py-2 rounded-full text-sm shadow-md hover:bg-green-600 transition"
            >
              Rediger
            </Link>
            <button
              onClick={() => initiateVisit(gravestone?.id)}
              className="bg-green-grave text-white px-4 py-2 rounded-full text-sm shadow-md hover:bg-green-600 transition flex items-center"
            >
              <FaPlusCircle className="h-5 w-5 mr-1" />
              Nytt Besøk
            </button>
          </div>
        )}
      </div>

      {/* Details Section */}
      <div className="lg:col-span-1 p-6 bg-gray-50 rounded-lg shadow-sm">
        <h2 className="font-semibold text-2xl text-gray-800 mb-4">Gravstein Info</h2>
        <div className="space-y-4">
          <DetailCard icon={<FaUser />} label="Kunde" value={gravestone?.customer_name} linkTo={`/kunder/${gravestone?.customer_id}`} />
          <DetailCard icon={<FaChurch />} label="Kirkegård" value={gravestone?.graveyard_name || "Ukjent"} linkTo={`/kirkegarder/${gravestone?.graveyard_id}`} />
          <DetailCard icon={<FaMapMarkerAlt />} label="Aktiv?" value={gravestone?.active ? "Ja" : "Nei"} />
          <DetailCard icon={<FaMapMarkerAlt />} label="Plassering" value={gravestone?.location || "Ukjent"} />
          <DetailCard icon={<FaGlobe />} label="Koordinater" value={`${gravestone?.latitude || 'Ukjent'}, ${gravestone?.longitude || 'Ukjent'}`} />
          <DetailCard icon={<FaThList />} label="Felt, Rad, Plass" value={`Felt: ${gravestone?.field || "Ukjent"}, Rad: ${gravestone?.row || "Ukjent"}, Plass: ${gravestone?.place || "Ukjent"}`} />
          <DetailCard icon={<FaBox />} label="Produkter" value={gravestone?.productDetails?.length ? gravestone.productDetails.map(p => p.name).join(', ') : "Ingen"} />
          <DetailCard icon={<FaExclamationTriangle />} label="Advarsel" value={gravestone?.warning || "Ingen"} />
          <DetailCard icon={<FaCogs />} label="Automatisk?" value={gravestone?.is_auto ? "Ja" : "Nei"} />
          <DetailCard icon={<FaCalendarAlt />} label="Dager mellom besøk" value={gravestone?.days_between_visits || "Ikke spesifisert"} />
          <DetailCard icon={<FaGlobe />} label="Sist Besøkt" value={gravestone?.latest_visit_date ? new Date(gravestone?.latest_visit_date).toLocaleString("nb-NO") : "Ukjent"} />
        </div>
      </div>

      {/* Graveperson Cards Section */}
      <div className="lg:col-span-1 p-6 bg-white rounded-lg shadow-sm">
        <h2 className="font-semibold text-2xl mb-4 text-gray-800">Gravpersoner</h2>
        <div className="space-y-4">
          {gravestone?.gravepersons?.length > 0 ? (
            gravestone.gravepersons.map((person) => (
              <PersonCard key={person.id} person={person} linkToGraveperson={true} />
            ))
          ) : (
            <p className="text-gray-500">Ingen gravpersoner funnet.</p>
          )}

          {user?.type === "admin" && (
            <Link
              to={`/gravpersoner/ny/${id}`}
              className="flex justify-center items-center bg-green-grave hover:bg-orange-grave text-white rounded-lg shadow-lg p-4 transition-colors duration-300"
            >
              <FaPlusCircle className="text-2xl" />
              <span className="ml-2">Legg til gravperson</span>
            </Link>
          )}
        </div>
        <div className="mt-5">
        <h2 className="font-semibold text-2xl mb-4 text-gray-800">Gravstein Kontakter</h2>
          <GravestoneContactsManager gravestoneId={id} />
        </div>
      </div>
    </div>
  </div>
);

  const groupVisitsByYear = (visits) => {
    return visits.reduce((acc, visit) => {
      const year = new Date(visit.visit_date).getFullYear();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(visit);
      return acc;
    }, {});
  };

  const renderVisitsTimeline = () => {
    const visitsByYear = groupVisitsByYear(visits);

    const handleWidthChange = (event) => {
      setWidth(event.target.value);
    };

    return (
      <div>
        <div className="max-w-lg mx-auto p-4 flex items-center space-x-4">
          <label
            htmlFor="width-slider"
            className="text-sm font-medium text-gray-900 whitespace-nowrap"
          >
            Endre størrelse:
          </label>
          <input
            id="width-slider"
            type="range"
            min="50"
            max="100"
            value={width}
            onChange={handleWidthChange}
            className="flex-grow h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
          />
          <span className="text-sm font-medium text-gray-900">{width}%</span>
        </div>
        <div className="mx-auto p-4" style={{ width: `${width}%` }}>
          <div className="relative border-l-4 border-green-grave pl-4">
            {Object.keys(visitsByYear)
              .sort((a, b) => b - a) // Sort years in descending order
              .map((year) => (
                <div key={year} className="mb-10">
                  <div className="flex items-center mb-4">
                    <div className="flex-shrink-0 h-12 px-4 rounded-full bg-green-grave flex items-center justify-center text-white text-lg font-bold">
                      {year} ({visitsByYear[year].length} Besøk)
                    </div>
                  </div>
                  {visitsByYear[year].map((visit) => (
                    <div key={visit.id} className="mb-10 ml-4 relative">
                      <div className="flex flex-col md:flex-row items-start md:items-center mb-4">
                        <Link
                          to={
                            visit.is_legacy
                              ? `/besok/${visit.id}/legacy`
                              : `/besok/${visit.id}`
                          }
                          className="flex-shrink-0 h-10 px-4 rounded-full bg-green-grave flex items-center justify-center text-white font-bold hover:bg-orange-grave mb-2 md:mb-0"
                        >
                          {new Date(visit.visit_date).toLocaleString("nb-NO")}
                        </Link>
                        <div className="flex-grow border-t-2 border-green-grave md:ml-4"></div>
                        <div className="flex-shrink-0 md:pl-4 mt-2 md:mt-0">
                          <div className="text-green-grave">
                            <span className="text-md font-semibold">
                              Besøkt av{" "}
                              <Link
                                to={"/brukere/" + visit.user_id}
                                className="hover:underline hover:text-orange-grave"
                              >
                                {visit.user_name}
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                        {[
                          visit.before_picture_url,
                          visit.after_picture_url,
                        ].map((picture_url, i) => (
                          <div key={i} className="relative md:w-1/2">
                            <ImageWithFallback
                              src={picture_url}
                              mainURL={mainURL}
                              fallbackSrc="/images/default_grave.jpg" // Ensure this path exists in your public folder
                              alt={i === 0 ? "Before" : "After"}
                              className="object-cover rounded-lg shadow-lg w-full h-full"
                            />
                            {/* "Bruk som hovedbilde" button only visible to admins */}
                            {user.type === "admin" && (
                              <div className="absolute inset-0 bg-black bg-opacity-25 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                                <button
                                  className="px-4 py-2 bg-green-grave text-white rounded hover:bg-orange-grave"
                                  onClick={() =>
                                    handleSetMainImage(picture_url)
                                  }
                                >
                                  Bruk som hovedbilde
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="mt-4 p-4 bg-gray-50 rounded-lg shadow-md">
                        <div className="text-sm text-gray-800">
                          <strong className="block mb-1 text-green-grave">
                            Arbeid Utført:
                          </strong>
                          <div className="flex flex-wrap mt-2">
                            {visit.activities.map((activity, index) => (
                              <span
                                key={index}
                                className="bg-green-grave text-white px-3 py-1 rounded-full text-md font-bold cursor-default hover:bg-orange-grave mr-2 mb-2 shadow-sm"
                              >
                                {activity}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="text-sm text-gray-800 mt-4">
                          <strong className="block mb-1 text-green-grave">
                            Kommentar:
                          </strong>
                          <p className="bg-white p-3 rounded-lg shadow-inner">
                            {visit.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  // Handler function for updating the main image
  const handleSetMainImage = async (imageUrl) => {
    try {
      const response = await fetch(`/api/gravestones/${id}/picture`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pictureUrl: imageUrl }),
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Error updating main image");
      }

      // Success feedback
      console.log("Main image updated successfully:", data);
      alert("Hovedbilde oppdatert!");
    } catch (error) {
      console.error("Error:", error);
      alert("Feil ved oppdatering av hovedbilde.");
    }
  };

  if (isLoading) return <LoadingScreen />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="">
      <div className="mb-4 ml-4 mt-4 flex flex-col sm:flex-row sm:justify-between sm:items-center">
        {/* Tab selection */}
        <div className="flex mb-4 sm:mb-0">
          <button
            className={`mr-4 ${
              activeTab === "info"
                ? "text-green-grave font-bold"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab("info")}
          >
            Gravstein
          </button>
          <button
            className={`mr-4 ${
              activeTab === "visits"
                ? "text-green-grave font-bold"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab("visits")}
          >
            Besøk
          </button>
        </div>
      </div>
      {/* Content based on active tab */}
      {activeTab === "info" && renderGravestoneInfo()}
      {activeTab === "visits" && renderVisitsTimeline()}
    </div>
  );
};

export default GravestoneProfile;
