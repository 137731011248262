import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../context/UserContext';

const ProtectedRoute = ({ element: Element }) => {
  const { user, login } = useUser();
  const location = useLocation();
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    // Check if there’s a token in localStorage and user data hasn’t been set in context
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    const userName = localStorage.getItem('userName');
    const userType = localStorage.getItem('userType');

    if (accessToken && !user) {
      login({ id: userId, name: userName, type: userType });
    }

    setIsAuthChecked(true);
  }, [user, login]);

  if (!isAuthChecked) {
    // If still checking auth, render nothing or a loading indicator
    return <div>Loading...</div>;
  }

  if (!user) {
    // Redirect to login if the user is not authenticated
    return <Navigate to="/logginn" state={{ from: location }} replace />;
  }

  // If authenticated, render the component
  return Element;
};

export default ProtectedRoute;